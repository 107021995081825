$color-primary: #B7E8E5;

body {
	color: #504B46;
	font: 16px/1.8 'DNPShueiMGoStd-L';

	@media (max-width: 575px) {
		font-size: 14px;
		line-height: normal; } }

*, ::after, ::before {
	box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem; }

a {
	text-decoration: none; }

p {
	margin-top: 0;
	margin-bottom: 0; }

dl, ol, ul {
	margin-top: 0;
	margin-bottom: 1rem; }

ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0; }

img {
	max-width: 100%;
	height: auto; }

a[href^="tel:"] {
	color: #504B46;
	cursor: default;
	text-decoration: none;
	pointer-events: none;
	@media (max-width: 1024px) {
		pointer-events: auto; } }

.ms-auto {
	margin-left: auto !important; }

.fluid-img {
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat; }

/* fix col */

.row {
	&.-x15 {
		--bs-gutter-x: 15px; }

	&.-x30 {
		--bs-gutter-x: 30px; }

	&.-x60 {
		--bs-gutter-x: 60px; } }

.row.no-gutters80 {
	margin-right: -40px;
	margin-left: -40px; }

.row.no-gutters80 > [class^="col"], .row.no-gutters80 > [class*=" col"] {
	padding-right: 40px;
	padding-left: 40px; }

//
.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #504B46;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	border-radius: .25rem;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

.btn-primary {
	height: 50px;
	color: #504B46;
	background: #B7E8E5;
	border: 1px solid #B7E8E5;
	border-radius: 10px;
	min-width: 200px;
	box-shadow: 3px 3px rgba(80, 75, 70, 0.3);
	margin-bottom: 15px;
	line-height: 40px;

	&.btn-lg {
		min-width: 250px; }

	&.btn-sm {
		min-width: 135px;
		height: 40px;
		line-height: 30px; }

	&:hover {
		background: #7bded8;
		color: #fff; } }

.text-center {
	text-align: center !important; }

.text-start {
	text-align: left !important; }

.text-end {
	text-align: right !important; }

.mb-3 {
	margin-bottom: 1rem !important; }

.mb-4 {
	margin-bottom: 1.5rem !important; }

/*Flex */
.d-none {
	display: none !important; }

.d-flex {
	display: flex !important; }

@media (min-width: 768px) {
	.d-md-flex {
		display: flex !important; }

	.d-md-none {
		display: none !important; }

	.d-md-block {
		display: block !important; }

	.text-md-start {
		text-align: left !important; } }

@media (min-width: 576px) {
	.d-sm-flex {
		display: flex !important; }

	.d-sm-none {
		display: none !important; }

	.d-sm-block {
		display: block !important; } }

.justify-content-around {
	justify-content: space-around !important; }

.justify-content-center {
	justify-content: center !important; }

.align-items-center {
	align-items: center !important; }

.container, .container-sm, .container-1024, .container-1200, .container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto; }

.container {
	@media (min-width: 1200px) {
		max-width: 1250px; }

	@media (min-width: 1600px) {
		max-width: 1630px; } }

.container-sm {
	@media (min-width: 1200px) {
		max-width: 1080px; }

	@media (min-width: 1600px) {
		max-width: 1090px; } }

.container-1024 {
	max-width: 1054px; }

.container-1200 {
	max-width: 1230px; }

@media (min-width: 992px) {
	.col-lg-20 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%; } }

@media (min-width: 1600px) {
	.offset-lgg-1 {
		margin-left: 8.3333333333%; }

	.col-lgg-5 {
		flex: 0 0 auto;
		width: 41.6666666667%; } }

@media (min-width: 992px) {
	.order-lg-1 {
		order: 1 !important; }

	.order-lg-2 {
		order: 1 !important; } }

@media (min-width: 768px) {
	.order-md-1 {
		order: 1 !important; }

	.order-md-2 {
		order: 1 !important; }

	br.-mb {
		display: none; } }

@media (max-width: 767px) {
	br.-pc {
		display: none; } }

/*Fix image trong khung */
.dnfix__thumb {
	position: relative;
	height: auto;
	width: 100%;
	overflow: hidden;
	display: block;
	padding-top: 68.075%; }

.dnfix__thumb img {
	margin: 0px auto;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover; }

.dnfix__thumb.-contain img {
	object-fit: contain; }

/*sc-breadcrumb */
.sc-breadcrumb {
	margin-bottom: 30px;
	font-size: 15px;
	line-height: 26px;

	.el__wrap {
		@include flex-content(wrap, null, center);

		span {
			@include flexbox;

			margin-right: 10px; }

		a {
			display: inherit; } } }

.animation-button {
	position: relative;
	overflow: hidden;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		width: 100%;
		height: 100%;
		z-index: -1;
		background-color: #28a745;
		transform: translateX(-100%);
		transition: all 1s; } }

.animation-button-2 {
	position: relative;
	overflow: hidden;

	&:before {
		position: absolute;
		left: 0;
		top: 0;
		content: "";
		width: 100%;
		height: 100%;
		background-color: #fdf8e5;
		transform: translateX(-100%);
		transition: all 1s; } }

.animated {
	&.animation-button, &.animation-button-2 {
		&:before {
			transform: translateX(100%); } } }
