.header__textclgt{
		margin-top: 15px;
		.el{
				&__box{
						@include flex-content(wrap, null, center);
				}
				&--right{
						margin-left: auto;
				}
				&__title{
						font-size: 28px;
						line-height: 34px;
						color: #504B46;
						margin-bottom: 15px;
						img{
								position: relative;
								top: 3px;
								width: 400px;
								max-width: 100%;
								height: 35px;
								margin-right: 10px;
						}
				}
				&__tel{
						font-size: 23px;
						line-height: 27px;
						color: $color-primary;
						margin-bottom: 15px;
						margin-right: 30px;
						>span{
								font-size: 32px;
								line-height: 38px;
								margin-left: 8px;
						}
						a{color: $color-primary;}
				}
				&__btn{
						+ .el__btn{
								margin-left: 10px;
						}
				}
		}
		@media (max-width: 991px){

				.el__title{
						display: none;
				}

				.el--right {
						margin-left: 0;
						margin-top: 10px;
				}
		}
		@media (max-width: 767px){
				display: none;
		}

}
.page__header{
		position: relative;
		@include flex-content(wrap, null, center);
		padding: 30px 0;
		width: 100%;
		// background-image: url(../images/access-header-bg_1.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 100% 100%;
		&:before{
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url(../images/page-header-bg1.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
		}
		&:after{
				content: "";
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url(../images/page-header-bg2.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
		}

		.el{
				&__box{
						min-width: 320px;
						padding: 130px 111px;
						text-align: center;
						background-image: url(../images/page-header-box.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100% 100%;
						display: inline-block;
						margin: auto;
						z-index: 1;
						&__wrapper{
								position: relative;
								background-repeat: no-repeat;
								background-position: center;
								background-size: cover;
								&:before{
										content: "";
										position: absolute;
										top: -15px;
										left: -15px;
										width: calc(100% + 30px);
										height: calc(100% + 30px);
										background-image: url(../images/page__header-border.png);
										background-repeat: no-repeat;
										background-position: center;
										background-size: 100% 100%;
								}
						}
						@media (max-width: 1600px){
							padding: 4.5vw 111px;
						}
				}
				&__title{
						font-size: 44px;
						font-weight: 500;
						line-height: 77px;
						margin-bottom: 0;
						letter-spacing: 5px;
						font-family:'TsukuAVintageMinLPro-R';
				}
				&__sub{
						font-size: 35px;
						font-weight: 500;
						line-height: normal;
						color: $color-primary;
						margin-bottom: 0;
						font-family: 'KaufmannStd-Bold';
				}
		}
		@media (max-width: 991px){
				background-size: cover;
		}
		@media (max-width: 767px){
				.container{
						padding: 0;
				}
				.el{
						&__box {
								padding: 130px 75px;
								&__wrapper{
										background-size: cover;
										&:before {
												background-size: cover;
												left: 0;
												width: 100%;
												height: 100%;
										}
								}
						}
				}
		}
		@media (max-width: 575px){
				.el{
						&__box{
								padding: 130px 40px;
						}
						&__title{
								font-size: 28px;
								line-height: normal;
						}
						&__sub{
								font-size: 25px;
								line-height: normal;
						}
				}
		}
}
.access__map{
		padding: 50px 0;
		.el{
				&__header{
						text-align: center;
						margin-bottom: 50px;
						&__title{
								position: relative;
								display: inline-block;
								font-size: 38px;
								line-height: 66px;
								letter-spacing: 5px;
								font-weight: 500;
								margin-bottom: 0;
								font-family:'TsukuAVintageMinLPro-R';
								&::after{
										content: "";
										background-image: url(../images/access-header-line.png);
										background-repeat: no-repeat;
										background-position: center;
										background-size: 100% 100%;
										display: block;
										width: 100%;
										height: 15px;
								}
						}
				}
				&__map{
						margin-bottom: 50px;
				}
		}
		@media (max-width: 575px){
				padding: 30px 0;
				.el{
						&__header{
								&__title{
										font-size: 28px;
										line-height: normal;
								}
						}
				}
		}
}

.access__trans{
		padding: 50px 0;
		.el{
				&__header{
						&__title{
								font-size: 30px;
								font-weight: 500;
								line-height: 52px;
								color: #504B46;
						}
				}
				&__ul{
						margin-bottom: 0;
				}
				&__line{
						width: 100%;
						height: 17px;
						margin: 0 0;
						background-image: url(../images/access__trans-line-top.png);
						background-repeat:no-repeat;
						background-position: center;
						background-size: contain;
						&.-bottom{
								background-image: url(../images/access__trans-line-bottom.png);
						}
				}
				&__li{
						position: relative;
						+ li{
								&::before{
										content: "";
										background-image: url(../images/access__trans-border.png);
										background-repeat:no-repeat;
										background-position: center;
										background-size: 100% 100%;
										display: block;
										width: 100%;
										height: 3px;
								}
						}

				}
				&__li__wrap{
						@include flex-content(nowrap, null, center);
						padding: 25px 10px;
				}

				&__icon{
						@include flex-content(wrap,center,center);
						@include flex-col(94px);
						width: 94px;
						height: 94px;
						margin-right: 30px;
						background-image: url(../images/access__trans-circle.png);
						background-repeat:no-repeat;
						background-position: center;
						background-size: contain;
						img{
								max-width: 54px;
						}
				}
				&__title{
						@include flex-col(200px);
						margin-right: 30px;
						font-size: 24px;
						font-weight: 400;
						line-height: 42px;
						margin-bottom: 0;
				}
				&__sub{
						font-size: 16px;
						line-height: 1.8;
				}
		}
		@media (max-width: 767px){
				.el__title {
						@include flex-col(170px);
						font-size: 20px;
						line-height: normal;
				}
		}
		@media (max-width: 575px){
				text-align: center;
				.el{
						&__header{
								&__title{
										font-size: 28px;
										line-height: normal;
								}
						}
						&__li__wrap{
								display: block;
								padding: 15px 10px 15px;
						}
						&__icon{
								margin: auto;
						}
						&__title{
								@include flex-col(100%);
								margin-right: 0;
						}
				}

		}
}

.access__box3{
		padding: 100px 0;
		.el{
				&__thumb{
						padding-top: 68.075%;
				}
				&__item{
						margin-bottom: 30px;
				}
		}
		@media (max-width: 991px){
				padding: 50px 0;
		}

		@media (max-width: 575px){
				padding: 30px 0;
		}

}

.access__details{
		padding: 50px 0;
		overflow: hidden;
		.el{
				&__header{
						text-align: center;
						margin-bottom: 50px;
						&__title{
								position: relative;
								display: inline-block;
								font-size: 38px;
								line-height: 66px;
								font-weight: 500;
								letter-spacing: 5px;
								font-family:'TsukuAVintageMinLPro-R';
								&::after{
										content: "";
										background-image: url(../images/access-header-line.png);
										background-repeat: no-repeat;
										background-position: center;
										background-size: 100% 100%;
										display: block;
										width: 100%;
										height: 15px;
								}
						}
				}
				&__box{
						position: relative;
				}
				&__item{
						// @include flex-content(nowrap, null, center);
						padding:20px 20px;
						&--left{
								@include flex-col(160px);
								margin-right: 20px;
								text-align: center;
						}
						&--right{
								flex-grow: 1;
						}
						&.-st1{
								background-image: url(../images/home-clgt-item-1.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100% 100%;
						}
						&.-st2{
								background-image: url(../images/home-clgt-item-2.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100% 100%;
						}
						&.-st3{
								background-image: url(../images/home-clgt-item-3.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100% 100%;
						}
						&.-st4{
								background-image: url(../images/home-clgt-item-4.png);
								background-position: center;
								background-repeat: no-repeat;
								background-size: 100% 100%;
						}
				}
		}
		.table-schedule {
				width: 100%;
		}
		@media (max-width: 991px){
				.table-schedule th, .table-schedule td {
						min-width: 40px;
				}
		}
		@media (max-width: 767px){
				.el__item--left {
						font-size: 18px;
						margin-bottom: 8px;
						max-width: 100%;
						margin-right: 0;
				}
				.el__item--right {
						text-align: center;
				}
		}
		@media (max-width: 575px){

				.el__header {
						margin-bottom: 30px;
						&__title{
								font-size: 28px;
								line-height: normal;
						}
				}

				.table-schedule th, .table-schedule td {
						min-width: 20px;
				}
				font-size:14px;
				.el__box {
						padding: 0px;
				}
				.el__item {
						padding: 20px 20px;
				}

				.el__item--left {
						@include flex-col(100%);
						text-align: left;
				}
		}
}

.access__info{
		padding: 100px 30px 200px;
		.el{
				&__header{
						text-align: center;
						margin-bottom: 50px;
						&__title{
								position: relative;
								display: inline-block;
								font-size: 38px;
								line-height: 66px;
								font-weight: 500;
								letter-spacing: 5px;
								margin-bottom: 0;
								font-family:'TsukuAVintageMinLPro-R';
						}
				}
				&__box{
						position: relative;
						padding: 60px 85px 40px;
						background-image: url(../images/access__info-bg.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: 100% 100%;
						&:before{
								content: "";
								background-image: url(../images/ttl_information.svg);
								background-repeat: no-repeat;
								background-position: center;
								background-size: contain;
								position: absolute;
								top: -40px;
								left: -40px;
								width: 268px;
								height: 88px;
						}
				}
				&__item{
						position: relative;
						margin-bottom: 40px;
				}
				&__number{
						position: absolute;
						top: -15px;
						left: -15px;
						width: 73px;
						height: 73px;
						background-image: url(../images/access__info-number-1.png);
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;
						z-index: 1;
						font-size: 31px;
						line-height: 73px;
						text-align: center;
						color: #504B46;
						&.-st2{
								background-image: url(../images/access__info-number-2.png);
						}
				}
				&__thumb{
						position: relative;
						margin-bottom: 25px;
						.dnfix__thumb{
								padding-top: 68.075%;
						}
				}
				&__title{
						min-height: 115px;
						font-size: 16px;
						line-height: 1.8;
						color: #504B46;
						margin-bottom: 0;
				}
		}
		@media (max-width: 991px){
				padding: 100px 30px 100px;
				.el__box {
						padding: 60px 30px 30px;
				}
		}
		@media (max-width: 767px){
				.el__title{
						min-height: auto;
				}
				.el__box{
						background: transparent;
						padding: 60px 0px 30px;
						&:before {
								width: 230px;
								height: 75px;
								position: absolute;
								top: 0px;
								left: 0;
						}
				}

		}
		@media (max-width: 575px){
				padding: 0px 30px 0px;
				.el__box:before {
						top: -10px;
				}
				.el__header__title{
						font-size: 28px;
						line-height: normal;
				}
		}
		@media (min-width: 768px){
				.el{
						&__item{
								&:not(:nth-child(3n)){
										.el__thumb{
												&::after{
														content: "";
														position: absolute;
														background-image: url(../images/arow.png);
														background-repeat: no-repeat;
														background-position: center;
														background-size: cover;
														width: 20px;
														height: 38px;
														top: 50%;
														transform: translateY(-50%);
														right: -25px;
														color: $color-primary;
												}
										}
								}
						}
				}
		}
		@media ( min-width: 576px ) and ( max-width: 767px ) {
				.el{
						&__item{
								&:nth-child(2n + 1){
										.el__thumb{
												&::after{
														content: "";
														position: absolute;
														background-image: url(../images/arow.png);
														background-repeat: no-repeat;
														background-position: center;
														background-size: cover;
														width: 20px;
														height: 38px;
														top: 50%;
														transform: translateY(-50%);
														right: -25px;
														color: $color-primary;
												}
										}
								}
						}
				}
		}
}
