.header {
	padding: 10px 0px;

	&--mb {
		display: none;
		background-color: #fff; }

	&-mb__text {
		display: flex;
		a {
			display: flex;
			align-items: center; }
		img {
			width: 242px;
			height: 23px; } }

	@media (max-width: 991px) {
		&--mb {
			display: block;
			position: sticky;
			top: 0;
			z-index: 99;

			&.fixed-header {
				box-shadow: 0px 2px 5px #ccc; } }

		&__wrap {
			display: flex;
			justify-content: space-between;
			align-items: center; } } }

.btn-menu__mobile {
	display: none;
	position: relative;
	background: transparent;
	background-image: url(../images/menu-bg.svg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
	width: 31px;
	height: 31px;
	-webkit-transition: all ease .238s;
	-moz-transition: all ease .238s;
	transition: all ease .238s;

	&:before, &:after, span {
		background-color: #fff;
		-webkit-transition: all ease .238s;
		-moz-transition: all ease .238s;
		transition: all ease .238s; }

	&:before, &:after {
		content: '';
		position: absolute;
		top: 0;
		height: 2px;
		width: 15px;
		left: calc(50% - 8px);
		top: 50%;
		-webkit-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		border-radius: 10px; }

	span {
		position: absolute;
		width: 15px;
		height: 2px;
		left: calc(50% - 8px);
		top: 50%;
		overflow: hidden;
		text-indent: 200%;
		border-radius: 10px; }

	&:before {
		-webkit-transform: translate3d(0, -6px, 0);
		transform: translate3d(0, -6px, 0); }

	&:after {
		-webkit-transform: translate3d(0, 6px, 0);
		transform: translate3d(0, 6px, 0); }

	&.active {
		span {
			opacity: 0; }

		&:before {
			background-color: #333333;
			-webkit-transform: rotate3d(0, 0, 1, 45deg);
			transform: rotate3d(0, 0, 1, 45deg); }

		&:after {
			background-color: #333333;
			-webkit-transform: rotate3d(0, 0, 1, -45deg);
			transform: rotate3d(0, 0, 1, -45deg); } }

	@media (max-width: 991px) {
		display: block; } }

.nav-mobile {
	position: fixed;
	left: 0;
	bottom: 0;
	top: 50px;
	opacity: 0;
	width: 100%;
	z-index: 1001;
	overflow: auto;
	transform: translateX(-100%);
	transition: all .2s ease-in-out 0s;
	background: #fff;

	&__list {
		list-style: none;
		padding: 0;

		li {
			position: relative;
			font-size: 27px;
			font-weight: 700;

			img {
				margin-right: 10px; }

			a {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 15px 30px 15px;
				position: relative;
				color: #333333;

				@media (max-width: 479px) {
					padding-left: 30px; } }

			&.show-sub {
				&:before, &:after {
					position: absolute;
					right: 22px;
					top: 30px;
					content: "";
					width: 20px;
					height: 2px;
					transform: translateX(-50%);
					background-color: #b3b3b3;
					transition: all 0.3s;
					z-index: 99; }

				&:after {
					width: 2px;
					height: 20px;
					right: 40px;
					top: 22px; } }

			&.show-sub.active {
				&:after {
					opacity: 0; } }

			@media (max-width: 479px) {
				font-size: 20px;
				font-weight: 700; } }

		.sub-menu {
			list-style: none;
			padding: 0;
			height: 0;
			opacity: 0;
			overflow: hidden;
			transition: all 0.3s ease-in-out;
			background-color: #f4f4f4;

			li {
				padding: 0px 35px 0px 65px;

				@media (max-width: 479px) {
					padding-left: 30px; }

				a {
					position: relative;
					padding: 15px 0px 15px 15px;

					&:before {
						position: absolute;
						left: 0;
						top: 50%;
						content: "";
						width: 10px;
						height: 2px;
						background-color: #2e922e;
						transform: translateY(-50%); } } }

			&.show {
				height: auto;
				opacity: 1;
				overflow: visible; } } }

	@media (max-width: 479px) {
		top: 50px; } }

body.show-menu {
	overflow: hidden; }

.show-menu .nav-mobile {
	opacity: 1;
	transform: translateX(0); }
