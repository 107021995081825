@mixin flexbox {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex; }

%flexbox {
	@include flexbox; }

//----------------------------------
@mixin inline-flex {
	display: -webkit-inline-box;
	display: -webkit-inline-flex;
	display: -moz-inline-flex;
	display: -ms-inline-flexbox;
	display: inline-flex; }

%inline-flex {
	@include inline-flex; }

//----------------------------------------------------------------------
// Flexbox Direction
//
// The 'flex-direction' property specifies how flex items are placed in
// the flex container, by setting the direction of the flex container's
// main axis. This determines the direction that flex items are laid out in.
//
// Values: row | row-reverse | column | column-reverse
// Default: row
//
// http://w3.org/tr/css3-flexbox/#flex-direction-property
@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal; }
	@else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical; }
	@else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical; }
	@else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal; }

	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value; }

// Shorter version:
@mixin flex-dir($args...) {
	@include flex-direction($args...); }

//----------------------------------------------------------------------
// Flexbox Wrap
//
// The 'flex-wrap' property controls whether the flex container is single-line
// or multi-line, and the direction of the cross-axis, which determines
// the direction new lines are stacked in.
//
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-wrap-property
@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;

	@if $value == nowrap {
		-ms-flex-wrap: none; }
	@else {
		-ms-flex-wrap: $value; }

	flex-wrap: $value; }

//----------------------------------------------------------------------
// Flexbox Flow (shorthand)
//
// The 'flex-flow' property is a shorthand for setting the 'flex-direction'
// and 'flex-wrap' properties, which together define the flex container's
// main and cross axes.
//
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap
//
// http://w3.org/tr/css3-flexbox/#flex-flow-property
@mixin flex-flow($values: row nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values; }

//----------------------------------------------------------------------
// Flexbox Order
//
// The 'order' property controls the order in which flex items appear within
// their flex container, by assigning them to ordinal groups.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#order-property
@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int; }

//----------------------------------------------------------------------
// Flexbox Grow
//
// The 'flex-grow' property sets the flex grow factor. Negative numbers
// are invalid.
//
// Default: 0
//
// http://w3.org/tr/css3-flexbox/#flex-grow-property
@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int; }

//----------------------------------------------------------------------
// Flexbox Shrink
//
// The 'flex-shrink' property sets the flex shrink factor. Negative numbers
// are invalid.
//
// Default: 1
//
// http://w3.org/tr/css3-flexbox/#flex-shrink-property
@mixin flex-shrink($int: 1) {
	-webkit-flex-shrink: $int;
	-moz-flex-shrink: $int;
	-ms-flex-negative: $int;
	flex-shrink: $int; }

//----------------------------------------------------------------------
// Flexbox Basis
//
// The 'flex-basis' property sets the flex basis. Negative lengths are invalid.
//
// Values: Like "width"
// Default: auto
//
// http://www.w3.org/TR/css3-flexbox/#flex-basis-property
@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	-moz-flex-basis: $value;
	-ms-flex-preferred-size: $value;
	flex-basis: $value; }

//----------------------------------------------------------------------
// Flexbox "Flex" (shorthand)
//
// The 'flex' property specifies the components of a flexible length: the
// flex grow factor and flex shrink factor, and the flex basis. When an
// element is a flex item, 'flex' is consulted instead of the main size
// property to determine the main size of the element. If an element is
// not a flex item, 'flex' has no effect.
//
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).
//
// http://w3.org/tr/css3-flexbox/#flex-property
@mixin flex($fg: 1, $fs: null, $fb: null) {
	// Set a variable to be used by box-flex properties
	$fg-boxflex: $fg;

	// Box-Flex only supports a flex-grow value so let's grab the
	// first item in the list and just return that.
	@if type-of($fg) == "list" {
		$fg-boxflex: nth($fg, 1); }

	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb; }

//----------------------------------------------------------------------
// Flexbox Justify Content
//
// The 'justify-content' property aligns flex items along the main axis
// of the current line of the flex container. This is done after any flexible
// lengths and any auto margins have been resolved. Typically it helps distribute
// extra free space leftover when either all the flex items on a line are
// inflexible, or are flexible but have reached their maximum size. It also
// exerts some control over the alignment of items when they overflow the line.
//
// Note: 'space-*' values not supported in older syntaxes.
//
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start
//
// http://w3.org/tr/css3-flexbox/#justify-content-property
@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start; }
	@else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end; }
	@else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify; }
	@else if $value == space-around {
		-ms-flex-pack: distribute; }
	@else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value; }

	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value; }

// Shorter version:
@mixin flex-just($args...) {
	@include justify-content($args...); }

//----------------------------------------------------------------------
// Flexbox Align Items
//
// Flex items can be aligned in the cross axis of the current line of the
// flex container, similar to 'justify-content' but in the perpendicular
// direction. 'align-items' sets the default alignment for all of the flex
// container's items, including anonymous flex items. 'align-self' allows
// this default alignment to be overridden for individual flex items. (For
// anonymous flex items, 'align-self' always matches the value of 'align-items'
// on their associated flex container.)
//
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-items-property
@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start; }
	@else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end; }
	@else {
		-webkit-box-align: $value;
		-ms-flex-align: $value; }

	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value; }

//----------------------------------
// Flexbox Align Self
//
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto
@mixin align-self($value: auto) {
	// No Webkit Box Fallback.
	-webkit-align-self: $value;
	-moz-align-self: $value;

	@if $value == flex-start {
		-ms-flex-item-align: start; }
	@else if $value == flex-end {
		-ms-flex-item-align: end; }
	@else {
		-ms-flex-item-align: $value; }

	align-self: $value; }

//----------------------------------------------------------------------
// Flexbox Align Content
//
// The 'align-content' property aligns a flex container's lines within the
// flex container when there is extra space in the cross-axis, similar to
// how 'justify-content' aligns individual items within the main-axis. Note,
// this property has no effect when the flexbox has only a single line.
//
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch
//
// http://w3.org/tr/css3-flexbox/#align-content-property
@mixin align-content($value: stretch) {
	// No Webkit Box Fallback.
	-webkit-align-content: $value;
	-moz-align-content: $value;

	@if $value == flex-start {
		-ms-flex-line-pack: start; }
	@else if $value == flex-end {
		-ms-flex-line-pack: end; }
	@else {
		-ms-flex-line-pack: $value; }

	align-content: $value; }

@mixin fontsize($size, $base: 10) {
	font-size: $size + px; }

// for Google Chrome
@mixin body_fontsize($size, $base: 10) {
	font-size: $size + px;
	font-size: $size / $base * 1em; }

@mixin listclear {
	margin: 0;
	padding: 0;
	list-style: none; }

@mixin list-horizontal($padding: 0) {
	@include listclear;

	@if $padding != 0 {
		margin-left: -$padding; }

	@include fontsize(0);

	> li {
		display: inline;
		padding-left: $padding;
		padding-right: $padding;
		white-space: nowrap;

		@include fontsize($base_font_size); } }

@mixin image-replace {
	overflow: hidden;
	text-indent: 100%;
	white-space: nowrap; }

@mixin reset-appearance {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; }

@mixin v-gradient($top, $bottom) {
	background: -webkit-linear-gradient(top, $top 0%, $bottom 100%);
	background: linear-gradient(to bottom, $top 0%, $bottom 100%); }

@mixin letterpress($opacity: 0.5) {
	text-shadow: 0 1px 1px rgba(255, 255, 255, $opacity); }

@mixin blackShadow($opacity: 0.5) {
	text-shadow: 0 1px 1px rgba(0, 0, 0, $opacity); }

@mixin border-radius($borderRadius) {
	-webkit-border-radius: $borderRadius;
	-moz-border-radius: $borderRadius;
	border-radius: $borderRadius; }

@mixin box-shadow($boxShadow...) {
	-moz-box-shadow: $boxShadow;
	-webkit-box-shadow: $boxShadow;
	-ms-box-shadow: $boxShadow;
	-o-box-shadow: $boxShadow;
	box-shadow: $boxShadow; }

//border radius directions
@mixin border-top-radius($borderRadius) {
	-webkit-border-top-left-radius: $borderRadius;
	-webkit-border-top-right-radius: $borderRadius;
	-moz-border-radius-topleft: $borderRadius;
	-moz-border-radius-topright: $borderRadius;
	border-radius: $borderRadius $borderRadius 0 0; }

@mixin border-bottom-radius($borderRadius) {
	-webkit-border-bottom-left-radius: $borderRadius;
	-webkit-border-bottom-right-radius: $borderRadius;
	-moz-border-radius-bottomleft: $borderRadius;
	-moz-border-radius-bottomright: $borderRadius;
	border-radius: 0 0 $borderRadius $borderRadius; }

@mixin border-left-radius($borderRadius) {
	-webkit-border-top-left-radius: $borderRadius;
	-webkit-border-bottom-left-radius: $borderRadius;
	-moz-border-radius-topleft: $borderRadius;
	-moz-border-radius-bottomleft: $borderRadius;
	border-radius: $borderRadius 0 0 $borderRadius; }

@mixin border-right-radius($borderRadius) {
	-webkit-border-top-right-radius: $borderRadius;
	-webkit-border-bottom-right-radius: $borderRadius;
	-moz-border-radius-topright: $borderRadius;
	-moz-border-radius-bottomright: $borderRadius;
	border-radius: 0 $borderRadius $borderRadius 0; }

//for use of custom transitions
@mixin transition($transition) {
	-webkit-transition: $transition;
	-moz-transition: $transition;
	-ms-transition: $transition;
	-o-transition: $transition;
	transition: $transition; }

@mixin box-shadow-transition($duration) {
	-webkit-transition: box-shadow #{$duration}s ease;
	-moz-transition: box-shadow #{$duration}s ease;
	-o-transition: box-shadow #{$duration}s ease;
	transition: box-shadow #{$duration}s ease; }

//transformations
@mixin box-rotate($deg) {
	-webkit-transform: rotate(#{$deg}deg);
	-moz-transform: rotate(#{$deg}deg);
	-ms-transform: rotate(#{$deg}deg);
	-o-transform: rotate(#{$deg}deg);
	transform: rotate(#{$deg}deg); }

@mixin scale($ratio) {
	-webkit-transform: scale($ratio);
	-moz-transform: scale($ratio);
	-ms-transform: scale($ratio);
	-o-transform: scale($ratio);
	transform: scale($ratio); }

@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
	transform: translate($x, $y); }

@mixin skew($x, $y) {
	-webkit-transform: skew($x, $y);
	-moz-transform: skew($x, $y);
	-ms-transform: skew($x, $y);
	-o-transform: skew($x, $y);
	transform: skew($x, $y); }

@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
	-moz-transform: translate3d($x, $y, $z);
	-o-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z); }

//Add opacity to elements
@mixin opacity($opacity) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity}*10)";
	filter: alpha(opacity = $opacity * 100);
	-moz-opacity: $opacity;
	-khtml-opacity: $opacity;
	opacity: $opacity; }

// Add an alphatransparency value to any background or border color
@mixin translucent-background($color: #fff, $alpha: 0.5) {
	background: $color;

	//fallback
	background: hsla(hue($color), saturation($color), lightness($color), $alpha); }

@mixin translucent-border($size: 1px, $style: solid, $color: #fff, $alpha: 0.5) {
	border: $size $style hsla(hue($color), saturation($color), lightness($color), $alpha);
	background-clip: padding-box; }

//gradients
@mixin gradient($startColor, $endColor, $noGradient, $type: "vertical", $degOrImagePath: "") {
	@if $type == "vertical" {
		background: $noGradient;
		background: -moz-linear-gradient(top, $startColor, $endColor) repeat-x mix($startColor, $endColor, 60%);

		// FF 3.6+
		background: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)) repeat-x mix($startColor, $endColor, 60%);

		// Safari 4+, Chrome 2+
		background: -webkit-linear-gradient(top, $startColor, $endColor) repeat-x mix($startColor, $endColor, 60%);

		// Safari 5.1+, Chrome 10+
		background: -o-linear-gradient(top, $startColor, $endColor) repeat-x mix($startColor, $endColor, 60%);

		// Opera 11.10
		background: linear-gradient(to bottom, $startColor, $endColor) repeat-x mix($startColor, $endColor, 60%);

 }		// Standard, IE10

	@if $type == "horizontal" {
		background: $noGradient;
		background: -moz-linear-gradient(left, $startColor, $endColor) repeat-x $endColor;

		// FF 3.6+
		background: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor) repeat-x $endColor);

		// Safari 4+, Chrome 2+
		background: -webkit-linear-gradient(left, $startColor, $endColor) repeat-x $endColor;

		// Safari 5.1+, Chrome 10+
		background: -o-linear-gradient(left, $startColor, $endColor) repeat-x $endColor repeat-x $endColor;

		// Opera 11.10
		background: linear-gradient(to right, $startColor, $endColor) repeat-x $endColor;

 }		// Standard, IE10

	@if $type == "radial" {
		background: $noGradient;
		background: -webkit-gradient(radial, center center, 0, center center, 460, from($startColor), to($endColor)) no-repeat $endColor;
		background: -webkit-radial-gradient(circle, $startColor, $endColor) no-repeat $endColor;
		background: -moz-radial-gradient(circle, $startColor, $endColor) no-repeat $endColor;
		background: -o-radial-gradient(circle, $startColor, $endColor) no-repeat $endColor;
		background: radial-gradient(circle, $startColor, $endColor) no-repeat $endColor; }

	@if $type == "directional" {
		background: $noGradient;
		background: -moz-linear-gradient(#{$degOrImagePath}deg, $startColor, $endColor) repeat-x $endColor;

		// FF 3.6+
		background: -webkit-linear-gradient(#{$degOrImagePath}deg, $startColor, $endColor) repeat-x $endColor;

		// Safari 5.1+, Chrome 10+
		background: -ms-linear-gradient(#{$degOrImagePath}deg, $startColor, $endColor) repeat-x $endColor;

		// IE8/9
		background: -o-linear-gradient(#{$degOrImagePath}deg, $startColor, $endColor) repeat-x $endColor;

		// Opera 11.10
		background: linear-gradient(#{$degOrImagePath}deg, $startColor, $endColor) repeat-x $endColor;

 }		// Standard, IE10

	@if $type == "image" {
		background: $degOrImagePath no-repeat scroll $noGradient;
		background: $degOrImagePath no-repeat scroll, -webkit-gradient(linear, left bottom, left top, from($endColor), to($startColor));
		background: $degOrImagePath no-repeat scroll, -webkit-linear-gradient(center top, $startColor, $endColor);
		background: $degOrImagePath no-repeat scroll, -moz-linear-gradient(center top, $startColor, $endColor);
		background: $degOrImagePath no-repeat scroll, -ms-linear-gradient(center top, $startColor, $endColor);
		background: $degOrImagePath no-repeat scroll, -o-linear-gradient(center top, $startColor, $endColor);
		background: $degOrImagePath no-repeat scroll, linear-gradient(center top, $startColor, $endColor); } }

@mixin gradient-vertical-three-colors($startColor: #00b3ee, $midColor: #7a43b6, $colorStop: 50%, $endColor: #c3325f, $noGradient: #444) {
	background: $noGradient;
	background: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor)) no-repeat mix($midColor, $endColor, 80%);
	background: -webkit-linear-gradient($startColor, $midColor $colorStop, $endColor) no-repeat mix($midColor, $endColor, 80%);
	background: -moz-linear-gradient(top, $startColor, $midColor $colorStop, $endColor) no-repeat mix($midColor, $endColor, 80%);
	background: -o-linear-gradient($startColor, $midColor $colorStop, $endColor) no-repeat mix($midColor, $endColor, 80%);
	background: linear-gradient($startColor, $midColor $colorStop, $endColor) no-repeat mix($midColor, $endColor, 80%); }

@mixin gradient-vertical-button($highlightColor: #fbeda1, $topColor: #FFBB02, $bottomColor: #EB6900, $shadowColor: #b07504) {
	background: $bottomColor;
	background: -moz-linear-gradient(top, $highlightColor 0%, $topColor 4%, $bottomColor 95%, $shadowColor 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $highlightColor), color-stop(4%, $topColor), color-stop(95%, $bottomColor), color-stop(100%, $shadowColor));
	background: -webkit-linear-gradient(top, $highlightColor 0%, $topColor 4%, $bottomColor 95%, $shadowColor 100%);
	background: -o-linear-gradient(top, $highlightColor 0%, $topColor 4%, $bottomColor 95%, $shadowColor 100%);
	background: -ms-linear-gradient(top, $highlightColor 0%, $topColor 4%, $bottomColor 95%, $shadowColor 100%);
	background: linear-gradient(to bottom, $highlightColor 0%, $topColor 4%, $bottomColor 95%, $shadowColor 100%); }

// triangles ($direction can be: up, down, left, right, up-right, up-left, down-righ or down-left)
@mixin triangle($size, $color, $direction) {
	height: 0;
	width: 0;

	@if $direction == up or $direction == down or $direction == right or $direction == left {
		border-color: transparent;
		border-style: solid;
		border-width: $size / 2;

		@if $direction == up {
			border-bottom-color: $color; }
		@else if $direction == right {
			border-left-color: $color; }
		@else if $direction == down {
			border-top-color: $color; }
		@else if $direction == left {
			border-right-color: $color; } }
	@else if $direction == up-right or $direction == up-left {
		border-top: $size solid $color;

		@if $direction == up-right {
			border-left: $size solid transparent; }
		@else if $direction == up-left {
			border-right: $size solid transparent; } }
	@else if $direction == down-right or $direction == down-left {
		border-bottom: $size solid $color;

		@if $direction == down-right {
			border-left: $size solid transparent; }
		@else if $direction == down-left {
			border-right: $size solid transparent; } } }

//hide text for use with background images
@mixin hide-text {
	overflow: hidden;
	text-indent: -9999px;
	display: block; }

//for custom @font-face
@mixin family($family: "", $url: "/fonts/", $weight: normal, $style: normal) {
	@font-face {
		font-family: $family;
		src: url("#{$url}.eot");
		src: url("#{$url}.eot?#iefix") format("embedded-opentype"), url("#{$url}.woff") format("woff"), url("#{$url}.ttf") format("truetype"), url("#{$url}.svg#svg") format("svg");
		font-weight: $weight;
		font-style: $style; } }

// Clearfix for clearing floats like a boss (from h5bp.com/q)
@mixin clearfix {
	zoom: 1;

	&:before,
	&:after {
		display: table;
		content: "";
		zoom: 1; }

	&:after {
		clear: both; } }

// browser specific-------------------------
@mixin ie7-inline-block {
	vertical-align: baseline;

	// for all other browsers
	*vertical-align: auto;

	// set for consistency in IE7
	*display: inline;

	// IE7 inline-block hack
	*zoom: 1;

 }	//enables hasLayout

// IE7 likes to collapse whitespace on either side of the inline-block elements.
// Ems because we're attempting to match the width of a space character. Left
// version is for form buttons, which typically come after other elements, and
// right version is for icons, which come before. Applying both is ok, but it will
// mean that space between those elements will be .6em (~2 space characters) in IE7,
// instead of the 1 space in other browsers.
@mixin ie7-restore-left-whitespace {
	*margin-left: .3em;

	&:first-child {
		*margin-left: 0; } }

@mixin ie7-restore-right-whitespace {
	*margin-right: .3em;

	&:last-child {
		*margin-left: 0; } }

@mixin custom_translation($time) {
	-webkit-transition: all #{$time}s ease-in-out;
	-moz-transition: all #{$time}s ease-in-out;
	-ms-transition: all #{$time}s ease-in-out;
	-o-transition: all #{$time}s ease-in-out;
	transition: all #{$time}s ease-in-out; }

@mixin tablet {
	@media (min-width: 768px) and (max-width: 991px) {
		@content; } }

@mixin desktop {
	@media (min-width: 1200px) {
		@content; } }

@mixin desktop_small {
	@media (min-width: 992px) and (max-width: 1199px) {
		@content; } }

@mixin mobile {
	@media (max-width: 767px) {
		@content; } }

@mixin mobile_normal {
	@media (max-width: 600px) {
		@content; } }

@mixin mobile_small {
	@media (max-width: 480px) {
		@content; } }

@mixin tablet_desktop {
	@media (min-width: 768px) {
		@content; } }

@mixin respond-min($min) {
	@media screen and (min-width: $min + px) {
		@content; } }

@mixin respond-min-max($min, $max) {
	@media screen and (min-width: $min + px) and (max-width: $max + px) {
		@content; } }

@mixin respond-max($max) {
	@media screen and (max-width: $max + px) {
		@content; } }

@mixin flex-content($wrap: null, $justify-content: null, $align-items: null) {
	@include flexbox;

	@if $wrap {
		@include flex-wrap($wrap); }

	@if $justify-content != null {
		@include justify-content($justify-content); }

	@if $align-items {
		@include align-items($align-items); } }

// Ex $width: 30% ...
@mixin flex-col($width) {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 $width;
	flex: 0 0 $width;
	max-width: $width; }
