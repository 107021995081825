/* ************************
 title
 ************************ */
.error {
	padding: 50px 0;
	.el {
		&__header {
			text-align: center;
			margin-bottom: 50px;
			&__title {
				position: relative;
				display: inline-block;
				font-size: 38px;
				line-height: 66px;
				letter-spacing: 5px;
				font-weight: 500;
				margin-bottom: 0;
				font-family: 'TsukuAVintageMinLPro-R';
				&::after {
					content: "";
					background-image: url(../images/access-header-line.png);
					background-repeat: no-repeat;
					background-position: center;
					background-size: 100% 100%;
					display: block;
					width: 100%;
					height: 15px; } } } }
	.error-text {
		font-size: 22px;
		line-height: 1.8;
		text-align: center; }
	.error-text,.error-btn {
		margin-top: 30px; }
	@media (max-width: 575px) {
		padding: 30px 0;
		.el {
			&__header {
				&__title {
					font-size: 22px;
					line-height: normal; } } }
		.error-text {
			font-size: 18px;
			text-align: left; } } }
