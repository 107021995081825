
// 水彩線
.m-el__circle__header {
	margin-bottom: 100px;
	text-align: center;
	&__wrap {
		position: relative;
		display: inline-block;
		&:before {
			content: "";
			background-image: url(../images/home/home-clinic-title.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			top: -90px;
			left: -85px;
			width: 365px;
			height: 235px;
			z-index: -1; } }
	&__title {
		font-size: 44px;
		letter-spacing: 9px;
		line-height: normal;
		font-family: 'TsukuAVintageMinLPro-R';
		font-weight: 400;
		margin-bottom: 0; }
	&__sub {
		font-size: 35px;
		line-height: normal;
		color: $color-primary;
		font-family: 'KaufmannStd-Bold'; }
	@media (max-width: 575px) {
		margin-bottom: 50px;
		&__title {
			font-size: 28px; }
		&__sub {
			font-size: 25px; }
		&__wrap:before {
			top: -60px;
			left: -60px;
			width: 225px;
			height: 160px; } } }


// 水彩線
.m-el__flag__header {
	margin-bottom: 100px;
	text-align: center;
	&__wrap {
		position: relative;
		display: inline-block;
		padding-bottom: 100px;
		&:before {
			content: "";
			background-image: url(../images/bg_title_flag_#{$colorname}.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 300px;
			height: 80px;
			z-index: -1;
			transform: translateX(-50%); } }
	&__title {
		font-size: 44px;
		letter-spacing: 9px;
		line-height: normal;
		font-family: 'TsukuAVintageMinLPro-R';
		font-weight: 400;
		margin-bottom: 0; }
	&__sub {
		font-size: 35px;
		line-height: normal;
		color: $color-primary;
		font-family: 'KaufmannStd-Bold'; }
	@media (max-width: 575px) {
		margin-bottom: 50px;
		&__title {
			font-size: 28px; }
		&__sub {
			font-size: 25px; }
		&__wrap:before {
			// top: -60px
			// left: -60px
			width: 225px;
			height: 160px; } } }



// 水彩線
.m-el__line__header {
	text-align: center;
	margin-bottom: 70px;
	&__title {
		position: relative;
		display: inline-block;
		font-size: 38px;
		line-height: 66px;
		letter-spacing: 5px;
		font-weight: 500;
		margin-bottom: 0;
		font-family: 'TsukuAVintageMinLPro-R';
		&::after {
			content: "";
			background-image: url(../images/access-header-line.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 100%;
			display: block;
			width: 100%;
			max-width: 200px;
			height: 15px;
			margin: auto; } }
	@media (max-width: 575px) {
		&__title {
			font-size: 28px;
			line-height: normal; } } }
