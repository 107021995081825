.staff__intro{
    padding: 160px 0 170px;

    background-image: url(../images/staff__intro-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .el{
        &__header{
            text-align: center;
            margin-bottom: 60px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 33px;
                line-height: 57px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
            }
            &__excerpt{
                margin-bottom: 30px;
            }
        }
    }
    @media (max-width: 767px){
        padding: 80px 0 60px;
        .el{
            &__header {
                margin-bottom: 40px;
                &__title{
                    font-size: 30px;
                }
                &__excerpt {
                    text-align: center;
                }
            }
        }
    }
    @media (max-width: 575px){
        .el{
            &__header{
                &__title{
                    font-size: 25px;
                    line-height: 1.4;
                }
                &__excerpt {
                    line-height: 1.6;
										text-align: left;
                }
            }
        }
    }
}

.staff__director{
    margin-top: 140px;
    padding-top: 20px;
    padding-bottom: 30px;
    background-image: url(../images/staff__director-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    .el{
        &__header{
            text-align: center;
            margin-bottom: 80px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 38px;
                line-height: 66px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
                &::after{
                    content: "";
                    background-image: url(../images/access-header-line.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    display: block;
                    width: 100%;
                    max-width: 200px;
                    height: 15px;
                    margin: auto;
                }
            }
            &__excerpt{
                margin-bottom: 30px;
            }
        }

        &__item{
            align-items: start;
            &--left{
                @include flex-col(360px);
                margin-right: 70px;
                margin-bottom: 30px;
            }
        }
        &__user{
            &__position{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.8;
                margin-bottom: 15px;
            }
            ruby{
                font-size: 26px;
                line-height: normal;
                margin-bottom: 30px;
            }
            rt{
                font-size: 14px;
                line-height: normal;
            }
        }

        &__title{
            font-size: 29px;
            font-weight: 400;
            line-height: 50px;
            color: #504B46;
            margin-bottom: 40px;
            font-family:'TsukuAVintageMinLPro-R';
        }
        &__thumb{
            position: relative;
            top: 30px;
            margin-bottom: 60px;
            .dnfix__thumb{
                padding-top: 121%;
            }
            &::after{
                content: "";
                position: absolute;
                top: -20px;
                left: -10px;
                width: calc(100% + 20px);
                height: calc(100% + 20px);
                background-image: url(../images/service__director-border.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
            }
        }
        &__excerpt{
            margin-bottom: 30px;
        }
    }
    @media (max-width: 991px){
        .el{
            &__item--left{
                @include flex-col(330px);
                margin-right: 30px;
            }
            &__box {
                padding: 0px 15px;
            }
            &__title {
                font-size: 25px;
                line-height: normal;
                margin-bottom: 20px;
            }

        }
    }
    @media (max-width: 767px){
        margin-top: 0;
        .el{
            &__header{
                margin-bottom: 60px;

                &__title {
                    font-size: 28px;
                    line-height: 1.8;
                }
            }
            &__item--left{
                margin: 0 auto 30px
            }
            &__title {
                font-size: 22px;
            }
        }
    }
    @media (max-width: 575px){
        padding-bottom: 0;
        .el{
            &__title {
                text-align: center;
            }

            &__excerpt {
                line-height: 1.6;
            }
        }
    }
}

.staff__profile{
    padding: 40px 40px 80px;
    .col--left{
        position: relative;
        &:after{
            content: "";
            background-image: url(../images/border-vertical-primary.png);
            background-repeat: repeat-y;
            background-position: center;
            background-size: contain;
            width: 4px;
            height: calc(100% - 20px);
            position: absolute;
            top: 20px;
            right: 0;
        }
    }
    .el{
        &__box{
            position: relative;
            background-image: url(../images/service__profile-border.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            padding: 30px;
            &:before{
                content: "";
                background-image: url(../images/ttl_profile.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 267px;
                height: 87px;
                position: absolute;
                top: -40px;
                left: -50px;
            }
        }
        &__item{
            padding: 0 15px;
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 30px;
            ul{
                margin-bottom: 0;
                li{
                    @include flexbox;
                    span{
                        @include flex-col(60px);
                        margin-right: 10px;
                    }
                }
            }
        }
        &__title{
            background-image: url(../images/service__profile-title-1.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 20px;
            color: #504B46;
            margin: auto;
            font-family: 'TsukuAVintageMinLPro-R';
            margin-bottom: 15px;
            &.-st2{
                background-image: url(../images/service__profile-title-2.png);
            }
        }
    }
    @media (max-width: 767px){
        .col--left:after {
            content: "";
            background-image: url(../images/border-primary.png);
            background-repeat: repeat-x;
            background-position: center;
            background-size: contain;
            width: 100%;
            height: 4px;
            position: relative;
            top: 0;
            display: block;
            margin-bottom: 30px;
        }
        .el{
            &__box:before{
                top: -30px;
                width: 230px;
                height: 76px;
            }


        }

    }

    @media (max-width: 575px){
        padding: 40px 0 0px;
        .el__box{
            background:#fff;
            padding: 30px 15px;
            &:before {
                width: 200px;
                top: -40px;
                left: -10px;
            }

        }
    }
}

.staff__doctor{
    padding-top: 130px;
    padding-bottom: 100px;
    .el{
        &__header{
            text-align: center;
            margin-bottom: 90px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 38px;
                line-height: 66px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
                &::after{
                    content: "";
                    background-image: url(../images/access-header-line.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    display: block;
                    width: 100%;
                    max-width: 200px;
                    height: 15px;
                    margin: auto;
                }
            }
            &__excerpt{
                margin-bottom: 30px;
            }
        }
        &__item{
            align-items: start;
            &--left{
                @include flex-col(292px);
                margin-right: 50px;
                margin-bottom: 30px;
            }
        }
        &__info{
            margin-bottom: 20px;
            &__position{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.8;
                margin-right: 30px;
                margin-bottom: 0;
                display: inline-block;
            }
            ruby{
                font-size: 26px;
            }
            rt{
                font-size: 14px;
            }
            &__wrap{
                display: inline-block;
            }
            &__name{
                font-size: 14px;
                line-height: normal;
            }
            &__address{
                font-size: 26px;
            }
        }

        &__title{
            font-size: 26px;
            font-weight: 400;
            line-height: 45px;
            color: #504B46;
            margin-bottom: 20px;
            font-family:'TsukuAVintageMinLPro-R';
        }
        &__thumb{
            position: relative;
            margin-bottom: 30px;
            .dnfix__thumb{
                padding-top: 100%;
                z-index: 1;
                max-width: 275px;
            }
            &::after{
                content: "";
                position: absolute;
                top: -10px;
                left: -20px;
                width: 100%;
                height: 100%;
                background-image: url(../images/service__doctor-border.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                z-index: 1;
            }
        }
        &__excerpt{
            font-size: 16px;
            line-height: 1.8;
            margin-bottom: 30px;
        }
        &__line{
            position: relative;
            width: 100%;
            height: 13px;
            margin: 5px 0;
            background-image: url(../images/line.png);
            background-repeat: repeat-x;
            background-position: center;
            background-size: contain;
            &::before,&::after{
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                width: 13px;
                height: 13px;
                background-image: url(../images/dot.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &::after{
                left: auto;
                right: -1px;
            }
        }
        &__ul{
            margin-bottom: 0;
        }
        &__li{
            position: relative;
            + .el__li{
                &::before{
                    content: "";
                    background-image: url(../images/access__trans-border.png);
                    background-repeat: repeat-x;
                    background-position: center;
                    background-size: cover;
                    display: block;
                    width: 100%;
                    height: 3px;
                }
            }

        }
        &__li__wrap{
            @include flex-content(nowrap, null, center);
            padding: 10px 10px;
        }

        &__icon{
            @include flex-col(100px);
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            font-family:'TsukuAVintageMinLPro-R';
            margin-right: 30px;
            margin-bottom: 0;
            background-image: url(../images/service__profile-title-1.png);
            background-repeat:no-repeat;
            background-position: center;
            background-size: contain;
            &.-st2{
                background-image: url(../images/service__profile-title-2.png);
            }
            img{
                max-width: 54px;
            }
        }
        &__sub{
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 0;
            flex-grow: 1;
            li{
                @include flexbox;
                span{
                    @include flex-col(60px);
                    margin-right: 10px;
                }
            }
        }

    }
    @media (max-width: 991px){
        padding-top: 50px;
        .el{

            &__header{
                margin-bottom: 50px;
            }
            &__box{
                padding: 0 15px;
            }
            &__item--left{
                margin-right: 30px;
            }
        }
    }
    @media (max-width: 767px){
        padding-top: 30px;
        .el{
            &__item--left{
                margin: 0 auto 0;
            }
            &__info {
                text-align: center;
            }
        }
    }
    @media (max-width: 575px){
        .el{
            &__header{
                &__title{
                    font-size: 28px;
                    line-height: normal;
                }
            }
            &__title{
                font-size: 22px;
                line-height: normal;
            }
        }
    }
    @media (max-width: 479px){
        .el{
            &__icon{
                margin: auto!important;
            }
            &__li__wrap{
                display: block;
            }
        }
    }
}

.staff__staff{
    padding-bottom: 15px;
    .el{

        &__header{
            text-align: center;
            margin-bottom: 60px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 38px;
                line-height: 66px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
                &::after{
                    content: "";
                    background-image: url(../images/access-header-line.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    display: block;
                    width: 100%;
                    max-width: 200px;
                    height: 15px;
                    margin: auto;
                }
            }
            &__excerpt{
                margin-bottom: 30px;
            }
        }
        &__item{
            margin-bottom: 30px;
        }
        &__thumb{
            position: relative;
            width: 240px;
            margin: 0 auto 30px;
            .dnfix__thumb{
                padding-top: 100%;
                border-radius: 50%;
            }
            &::after{
                content: "";
                position: absolute;
                top: 0;
                left: -10px;
                width: 100%;
                height: 100%;
                background-image: url(../images/staff-border-1.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
            }
            &.-st2{
                &::after{
                    background-image: url(../images/staff-border-2.png);
                }
            }
        }
        &__info{
            margin-bottom: 20px;
            &__position{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.8;
            }
            ruby{
                font-size: 26px;
            }
            rt{
                font-size: 14px;
            }
        }
        &__sub{
            margin-bottom: 30px;
            font-size: 16px;
            line-height: 28px;
        }
        &__line{
            position: relative;
            width: 100%;
            height: 4px;
            margin: 5px 0;
            background-image: url(../images/line-sm.png);
            background-repeat: repeat-x;
            background-position: center;
            background-size: 100% 100%;
            &::before,&::after{
                content: "";
                position: absolute;
                top: -4px;
                left: 0px;
                width: 13px;
                height: 13px;
                background-image: url(../images/dot.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &::after{
                left: auto;
                right: -1px;
            }
        }

        &__excerpt{
            position: relative;
            margin-top: 15px;
            margin-bottom: 15px;

            h4{
                font-weight: 400;
                margin-bottom: 0;
            }
            ul{
                margin-bottom: 0;
            }
        }
    }
    @media (max-width: 575px){
        .el{
            &__header{
                &__title{
                    font-size: 25px;
                    line-height: normal;
                }
            }
            &__excerpt {
                h4{
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 400;
                    font-family: 'TsukuAVintageMinLPro-R';
                    margin-right: 30px;
                    margin-bottom: 0;
                    background-image: url(../images/service__profile-title-1.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    margin: auto;
                    &.-st2{
                        background-image: url(../images/service__profile-title-2.png);
                    }
                }
            }
        }
    }
}

.staff__about{
    padding: 140px 0 150px;
    background-image: url(../images/staff__about-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    margin-bottom: 80px;
    .el{
        &__header{
            text-align: center;
            margin-bottom: 50px;
            &__title{
                font-size: 16px;
                font-weight: 400;
                line-height: 1.8;
                margin-bottom: 0;
            }
        }
        &__thumb{
            position: relative;
            margin: 0 12px;
            &::after{
                content: "";
                position: absolute;
                top: -20px;
                left: -20px;
                width: calc(100% + 40px);
                height: calc(100% + 20px);
                background-image: url(../images/staff__about-border.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
            }
        }
    }
    @media (max-width: 992px){
        padding: 90px 0 100px;
    }
    @media (max-width: 767px){
        padding: 40px 0 50px;
        .el__header {
            text-align: left;
        }
    }
}
