.service__nav{
    padding-top: 70px;
    margin-bottom: 70px;
    .el{
        &__header{
            text-align: center;
            margin-bottom: 70px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 38px;
                line-height: 66px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
                &::after{
                    content: "";
                    background-image: url(../images/access-header-line.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    display: block;
                    width: 100%;
                    max-width: 200px;
                    height: 15px;
                    margin: auto;
                }
            }
        }

        &__menu{
            @include flex-content(wrap, null, null);
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            margin-top: calc(var(--bs-gutter-y) * -1);
            margin-right: calc(var(--bs-gutter-x) / -2);
            margin-left: calc(var(--bs-gutter-x) / -2);
            a{
                position: relative;
                padding: 10px 30px 10px 10px;
                color: #504B46;
                font-size: 18px;
                line-height: 22px;
                display: block;
            }
            &>li{
                @include flex-col(25%);
                flex-shrink: 0;
                width: 100%;
                max-width: 100%;
                padding-right: calc(var(--bs-gutter-x) / 2);
                padding-left: calc(var(--bs-gutter-x) / 2);
                margin-top: var(--bs-gutter-y);
                text-align: center;
                position: relative;
                margin-bottom: 30px;
                a{
                    &:hover{
                       color: $color_primary;
                    }
                    &::before{
                        content: "";
                        width: 15px;
                        height: 15px;
                        background-image: url(../images/arow-down.png);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        display: block;
                        position: absolute;
                        top: 15px;
                        right: .75rem;
                    }
                    &::after{
                        content: "";
                        width: 100%;
                        height: 2px;
                        background-image: url(../images/menu-border-bottom.png);
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    .sub-menu{
        position: absolute;
        top: 100%;
        left: .75rem;
        width: calc(100% - 1.5rem);
        opacity: 0;
        background: #fff;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .3);
        transition: 0.5s;
        transform: rotateX(90deg);
        a{
            &::after{
                content: "";
                width: 100%;
                height: 2px;
                background-image: url(../images/menu-border-bottom.png);
                background-size: contain;
                background-position: center;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &:hover{
                background-color: $color_primary;
                color: #fff;
            }
        }
        &.show{
            visibility: visible;
            transform: rotateY(0);
            -webkit-transform: rotateY(0);
            opacity: 1;
            z-index: 99;
        }
    }
    @media (max-width: 991px){
        .el{
            &__menu{
                &>li{
                    @include flex-col(33.333333%);
                }
            }
        }
    }
    @media (max-width: 767px){
        padding-top: 50px;
        margin-bottom: 50px;
        .el{
            &__menu{
                &>li{
                    @include flex-col(50%);
                }
            }
        }
    }
    @media (max-width: 575px){
        .el{
            &__header{
                &__title{
                    font-size: 28px;
                    line-height: normal
                }
            }

            &__menu{
                &>li{
                    @include flex-col(100%);
                }
            }
        }
    }
}

.service__caries{
    padding-top: 50px;
    margin-bottom: 30px;
    .el{
        &__item{
            align-items: start;
        }
        &__title{
            font-size: 28px;
            font-weight: 400;
            line-height: 49px;
            color: #504B46;
            margin-bottom: 0;
        }
        &__badge{
            position: relative;
            display: inline-block;
            font-size: 20px;
            line-height: 1.8;
            padding: 4px 15px;
            margin-left: 30px;
            background-image: url(../images/service-badge-1.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            margin-bottom: 5px;
            &::after{
                content: "";
                position: absolute;
                top: 7px;
                right: -27px;
                width: 43px;
                height: 43px;
                background-image: url(../images/service-badge-2.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                z-index: -1;
            }
        }
        &__thumb{
            @include flex-col(390px);
            margin-right: 40px;
            margin-bottom: 30px;
            position: relative;
            border-radius: 20px;
            &::after{
                content: "";
                position: absolute;
                top: -10px;
                left: -10px;
                width: 100%;
                height: 100%;
                background-image: url(../images/service-border-wrap.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
            }
            .dnfix__thumb{
              border-radius: 30px;
              overflow: hidden;
              @media (max-width: 767px){
                border-radius: 20px;
              }
            }
        }
        &__excerpt{
            margin-bottom: 30px;
        }
        &__line{
            content: "";
            background-image: url(../images/service__caries-line.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
            height: 15px;
            display: block;
            margin-bottom: 50px;
        }
        &__list{
            list-style: none;
            font-size: 23px;
            line-height: 43px;
            li::before {
                content: "\2022";
                color: $color_primary;
                font-weight: bold;
                display: inline-block;
                font-size: 58px;
                line-height: 20px;
                margin-right: 5px;
                position: relative;
                top: 10px;
              }
        }
        &__button__wrap{
            text-align: right;
        }
    }
    @media (max-width: 991px){
        .el{
            &__thumb{
                @include flex-col(200px);
                margin-right: 15px;
            }
        }
    }
    @media (max-width: 767px){
        padding-top: 30px;

        .el{
            &__thumb{
                @include flex-col(100%);
                margin: 0 15px 30px;
            }
            &__badge {
                font-size: 16px;
                line-height: 1.8;
                padding: 2px 10px;
                margin-left: 0px;
                &::after {
                    top: 7px;
                    right: -15px;
                    width: 30px;
                    height: 32px;
                }
            }
            &__excerpt {
                font-size: 15px;
                line-height: 1.8;
            }
        }

    }
    @media (max-width: 575px){

        .el{
            &__list {
                font-size: 18px;
                line-height: normal;
            }
            &__title{
                font-size: 25px;
                line-height: normal;
            }
        }
    }
}

.service__cariesbox{
    margin-top: 30px;
    padding-bottom: 20px;

    .el2{
        &__box{
            background-image: url(../images/service__cariesbox-bg.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            padding: 40px 50px 10px;
        }
        &__title{
            font-size: 21px;
            font-weight: 400;
            line-height: 43px;
            color: #504B46;
            text-align: center;
            margin-bottom: 0;
        }
        &__thumb{
            @include flex-col(250px);
            margin-left: 30px;
            margin-bottom: 30px;
            .dnfix__thumb{
              border-radius: 20px;
              overflow: hidden;
            }
        }
        &__excerpt{
            margin-bottom: 30px;
        }
        &__line{
            content: "";
            background-image: url(../images/clinic-line.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            width: 100%;
            height: 9px;
            margin: 10px 0 30px;
            display: block;
        }
    }
    @media (max-width: 991px){
        .el2{
            &__box{
                // padding: 60px 44px;
            }
            &__thumb{
                @include flex-col(200px);
            }
        }
    }
    @media (max-width: 767px){
        .el2{
            &__thumb{
                @include flex-col(100%);
                margin: 0 auto 15px;
            }
            &__excerpt {
                font-size: 15px;
                line-height: 1.8;
            }
        }
    }
    @media (max-width: 575px){
        .el2{
            &__box{
                padding: 40px 30px 10px;
            }
            &__title{
                font-size: 22px;
                line-height: normal;
            }
        }
    }
}

.banner-btn.-st2{
    padding: 100px 0;
    .el{
        &__header{
            text-align: center;
            margin-bottom: 60px;
            &__title{
                position: relative;
                display: inline-block;
                font-size: 38px;
                line-height: 66px;
                letter-spacing: 5px;
                font-weight: 500;
                margin-bottom: 0;
                font-family:'TsukuAVintageMinLPro-R';
                &::after{
                    content: "";
                    background-image: url(../images/access-header-line.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    display: block;
                    width: 100%;
                    max-width: 200px;
                    height: 15px;
                    margin: auto;
                }
            }
            &__excerpt{
                margin-bottom: 80px;
                text-align: center;
            }
        }
    }
    @media (max-width: 767px){
        .el__header__excerpt {
            margin-bottom: 30px;
            text-align: left;
        }
    }
    @media (max-width: 575px){
        .el{
            &__header{
                &__title{
                    font-size: 28px;
                    line-height: normal;
                }
            }
        }
    }
}
