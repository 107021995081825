.header-wellcome{
    h1{
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
    }
    font-size: 13px;
    @media (max-width: 575px){
        font-size:12px;
        text-align: center;
    }
}
.home-slider2{
    overflow: hidden;
    background-image: url(../images/slider-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 30px;
    &:before{
        content: "";
        background-image: url(../images/Untitled-1.png);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .slick-slide {
        width: 1080px;
    }
    .slick-list {margin: 0 -20px;}
    .slick-slide>div {padding: 0 50px;}

    .el{
        &__item{
            position: relative;
            z-index: 1;
            &__wrap{
                position: relative;
                max-width: 900px;
                margin: auto;
                padding: 50px 0;
                &.-s1{
                    &:before{
                        content: "";
                        background-image: url(../images/slider-mask1-border.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: calc(100% + 85px);
                        height: calc(100% + 30px);
                        position: absolute;
                        top: -15px;
                        left: -50px;
                    }
                    &:after{
                        content: "";
                        background-image: url(../images/slider-mask1-border2.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: calc(100% + 45px);
                        height: calc(100% + 30px);
                        position: absolute;
                        top: -15px;
                        left: -30px;
                    }
                    .el__thumb{
                        img{
                            -webkit-mask-size: 100% 100%;
                            mask-size: 100% 100%;
                            -webkit-mask-repeat: no-repeat;
                            mask-repeat: no-repeat;
                            -webkit-mask-image: url(../images/slider-mask1.png);
                            mask-image: url(../images/slider-mask1.png);
                        }
                    }
                }
                &.-s2{
                    max-width: 830px;
                    &:before{
                        content: "";
                        background-image: url(../images/slider-mask2-border.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        max-width: 890px;
                        width: calc(100% + 60px);
                        height: 100%;
                        position: absolute;
                        top: -15px;
                        left: -35px;
                    }
                    &:after{
                        content: "";
                        background-image: url(../images/slider-mask2-border2.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: calc(100% + 30px);
                        height: 100%;
                        position: absolute;
                        top: -15px;
                        left: -30px;
                    }
                    .el__thumb{
                        padding-top: 66%;
                        img{
                            -webkit-mask-size: 100% 100%;
                            mask-size: 100% 100%;
                            -webkit-mask-repeat: no-repeat;
                            mask-repeat: no-repeat;
                            -webkit-mask-image: url(../images/slider-mask2.png);
                            mask-image: url(../images/slider-mask2.png);
                        }
                    }
                }
                &.-s3{
                    max-width: 830px;
                    &:before{
                        content: "";
                        background-image: url(../images/slider-mask3-border.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        max-width: 890px;
                        width: calc(100% + 60px);
                        height: 100%;
                        position: absolute;
                        top: -15px;
                        left: -35px;
                    }
                    &:after{
                        content: "";
                        background-image: url(../images/slider-mask3-border2.png);
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: calc(100% + 30px);
                        height: 100%;
                        position: absolute;
                        top: -15px;
                        left: -30px;
                    }
                    .el__thumb{
                        padding-top: 66%;
                        img{
                            -webkit-mask-size: 100% 100%;
                            mask-size: 100% 100%;
                            -webkit-mask-repeat: no-repeat;
                            mask-repeat: no-repeat;
                            -webkit-mask-image: url(../images/slider-mask3.png);
                            mask-image: url(../images/slider-mask3.png);
                        }
                    }
                }

            }
        }
        &__meta{
            position: absolute;
            top: 25%;
            right: 12%;
        }
        &__text{
            font-size: 27px;
            line-height: 55px;
            font-family: 'TsukuAVintageMinLPro-R';
            letter-spacing: 10px;
            text-align: center;
        }
        &__thumb{

            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding-top: 62%;
            min-height: 420px;
        }
    }
    @media (max-width: 1080px){
        .slick-slide {
            width: 767px;
        }
        .el__meta {
            top: 32%;
            right: 12%;
        }
        .el__text {
            font-size: 20px;
            line-height: normal;
        }
    }
    @media (max-width: 991px){
        &:before{
            background-size: 160%;
        }

        .el{
            &__item{
                &__wrap{
                    max-width: 600px;
                    &.-s1{
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: contain;
                            width: calc(100% + 45px);
                            height: calc(100% + 30px);
                            top: -15px;
                            left: -30px;
                        }
                    }
                    &.-s2{
                        max-width: 600px;
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: contain;
                            width: calc(100% + 30px);
                            height: 100%;
                            top: -15px;
                            left: -30px;
                        }
                        .el__thumb{
                            padding-top: 66%;
                        }
                    }
                    &.-s3{
                        max-width: 650px;
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: contain;
                            width: calc(100% + 30px);
                            height: 100%;
                            top: -15px;
                            left: -30px;
                        }
                        .el__thumb{
                            padding-top: 66%;
                        }
                    }

                }
            }
        }

    }
    @media (max-width: 767px){
        &:before{
            background-size: 180% 100%;
        }
        .el__meta {
            top: 26%;
            left: 0;
            width: 100%;
            letter-spacing: 5px;
        }

        .el{
            &__text {
                letter-spacing: 3px;
            }
            &__item{
                &__wrap{
                    max-width: calc(100% - 50px);
                    &.-s1{
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                    }
                    &.-s2{
                        max-width: calc(100% - 50px);
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        .el__thumb{
                            padding-top: 66%;
                        }
                    }
                    &.-s3{
                        max-width: calc(100% - 50px);
                        &:before{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        &:after{
                            background-size: 100% 100%;
                            width: calc(100% + 50px);
                            height: calc(100% - 50px);
                            top: 20px;
                            left: -30px;
                        }
                        .el__thumb{
                            padding-top: 66%;
                        }
                    }

                }
            }
        }
        .slick-slide > div{
            padding: 0 30px;
        }
    }
    @media (max-width: 575px){
        &:before{
            background-image: url(../images/Untitled-1-mb.png);
            background-size: 95% 90%;
        }
    }
}

.main__nav{
    font-size: 18px;
    line-height: 31px;
    margin-bottom: 50px;

    &.is-sticky{
        position: fixed;
        top: 0px;
        left: 0;
        width: 100%;
        background: #fff;
        z-index: 99;
        box-shadow: 0px 2px 5px #ccc;
    }

    .el__menu{
        >li{
            +li{
                &::after{
                    content: "";
                    width: 3px;
                    height: 43px;
                    background-image: url(../images/nav-line.png);
                    background-size: contain;
                    background-position: center;
                    background-repeat-x: repeat;
                    display: block;
                    position: absolute;
                    top: 18px;
                    left: 0;
                }
            }
        }
    }
    ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        .sub-menu {
            flex-direction: column;
            width:205px;position:absolute;

            background: #fff;
            border: 1px solid #ccc;
            opacity:0;
            visibility:hidden;
            transition:all 300ms linear;-o-transition:all 300ms linear;-ms-transition:all 300ms linear;
            -moz-transition:all 300ms linear;-webkit-transition:all 300ms linear;
            left:0px;top:100%;z-index:-1;text-align:left;
            li{
                &:not(:last-child){
                    border-bottom: 1px dashed #ccc;
                }
                &:hover{
                    background-color: #B7E8E5;
                    a{
                        color:#fff;
                    }
                }
            }
        }
        li{
            position: relative;
            text-align: center;
            flex-grow: 1;
            &:hover{
                .sub-menu{
                    display:block;opacity:1;visibility:visible;
                    z-index: 99;
                }
                a{
                    color:#B7E8E5;
                }
            }
            a{
                display: block;
                padding: 5px 30px;
                color: #504B46;
                text-decoration: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
            }
            img{
                display: block;
                margin: 0px auto 0;
                max-height: 25px;
            }

        }
    }
    @media (max-width: 992px){
        display: none;
    }

    @media (min-width: 993px){
        ul {
            flex-direction: row;
        }
    }

    .container {
        max-width: 1200px;
    }
}
@media (max-width: 992px){
    .sticky-wrapper{display: none;}
}


.home-intro{
    margin-bottom: 50px;
    &__meta{
        font-size: 18px;
        line-height: 1.8;
    }
    &__title{
        margin-bottom: 10px;
    }
    &__tel{
        font-size: 30px;
        line-height: normal;
        margin-bottom: 25px;
    }

    @media (max-width: 992px){
        text-align: center;
        .table-schedule{
            margin: 0 auto 10px;
        }
    }
    @media (max-width: 767px){
        .table-schedule{
            margin: 0 0 10px;
            width: 100%;
        }
        .home-intro__text{
            text-align:left;
        }
    }

    @media (max-width: 575px){
        margin-bottom: 20px;
        .home-intro__tel {
            display: none;
        }

        .home-intro__meta {
            font-size: 14px;
            line-height: normal;
            margin-bottom: 15px;
        }
    }

}

.schedule{
    overflow: hidden;
}
.table-schedule {
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
    color: #504B46;
    margin-bottom: 10px;
    th, td {
        text-align: center;
        vertical-align: middle;
        padding: 4px 12px;
        min-width: 44px;
        font-weight: 400;
    }
    tr {
        border-bottom: 1px solid #504B46;
    }
    tbody {
        td{
            color:#DEB69B;
        }
    }
    @media ( max-width: 1200px ) {
        th, td {
            padding: 5px 3px;
            min-width: 44px;
        }
    }
    @media ( max-width: 991px ){
        thead {
            th:first-child {
                font-size: 19.5px;
                min-width: 170px;
            }
        }
    }
    @media ( max-width: 1200px ) and ( min-width: 768px ) {
        th, td {
        padding: 4px 1px;
        letter-spacing: 0;
        }
    }
    @media ( max-width: 991px ) and ( min-width: 480px ) {
        thead {
            th:first-child {
                font-size: 19.5px;
            }
        }
    }

    @media ( max-width: 991px ) {
        th, td {
            min-width: 60px;
        }
    }
    @media ( max-width: 767px ) {
        th, td {
            min-width: 54px;
        }
        thead {
            th {
                &:first-child {
                    min-width: auto;
                }
            }
        }
    }
    @media  (max-width: 600px ){
        font-size: 14px;
    }
    @media  (max-width: 479px ){

        .schedule {
            font-size: 10px!important;
        }
        thead th:first-child {
            font-size: 12px!important;
            min-width: 105px;
        }

        th, td {
            min-width: 25px;
        }
    }
    @media  (max-width: 353px ){
        thead th:first-child {
            min-width: 85px;
        }
    }
}

.home-noteopen{
    .el{
        &__box{
            font-size: 16px;
            line-height: normal;
            padding: 60px 50px;
            text-align: center;
            width: 100%;
            margin-bottom: 30px;
            img{
                margin: 20px auto ;
            }
            &.-mask1{
                background-image: url(../images/note-mask.png);
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
            &.-mask2{
                background-image: url(../images/note-mask2.png);
                background-size: 100% 100%;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &__title{
            font-size: 30px;
            line-height: normal;
            color: #504B46;
            font-family: 'TsukuAVintageMinLPro-R';
            margin-bottom: 0;
            font-weight: 400;
        }
        &__sub{
            font-size: 38px;
            color: #504B46;
            margin-bottom: 30px;
        }
        &__sub2{
            font-size: 20px;
            color: #504B46;
            margin-bottom: 20px;
            span{
                margin-left: 20px;
            }
        }
    }

    @media (max-width: 991px){
        .el{
            &__sub2{
                span{
                    display: block;
                    margin-left: 0;
                }
            }
        }
    }
    @media (max-width: 575px){
        .el{
            &__sub {
                font-size: 25px;
                margin-bottom: 15px;
            }
            &__sub2{
                span{
                    display: block;
                    margin-left: 0;
                }
            }
            &__title {
                font-size: 22px;
            }
            &__box {
                font-size: 14px;
                padding: 30px 15px;
                margin-bottom: 15px;
                img {
                    margin: 10px auto;
                     max-width: 70%;
                }
            }
        }
    }
}

.banner-btn{
    padding: 50px 0 35px;
    .el{
          &__item{
            display: block;
            background-image: url(../images/banner-btn-border.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            border-radius: 20px;
            margin-bottom: 15px;
            font-size: 18px;
            color: #504B46;
            text-align: center;
            padding: 35px 15px;
            line-height: normal;
            transition: all .4s;
            &:hover{
                box-shadow: 0px 2px 5px #65605c;
            }
          }
    }
    @media (max-width: 767px){
        padding: 30px 0 15px;
        .el__item {
            padding: 29px 15px;
        }
    }
    @media (max-width: 575px){
        padding: 15px 0 0;
        &.-st2 {
            padding: 100px 0 50px;
        }
    }
}

.home-info{
    padding: 20px 0 35px;
    &__wrap {
        position: relative;
        margin-bottom: 20px;
        padding: 80px 100px 80px;
        background-image: url(../images/home-info.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        &:before{
            content: "";
            background-image: url(../images/home-info-top.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 140px;
            height: 132px;
            display: block;
            position: absolute;
            top: -30px;
            right: -45px;
        }
        &:after{
            content: "";
            background-image: url(../images/home-info-bottom.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 91px;
            height: 105px;
            display: block;
            position: absolute;
            bottom: -15px;
            left: -35px;
        }
    }
    .el{
        &__header{
            &__wrap{
                display: inline-block;
                margin: auto;
                position: relative;
                padding-left: 55px;
                margin-left: -55px;
                &:before{
                    content: "";
                    background-image: url(../images/bullhorn.svg);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 53px;
                    height: 32px;
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 0;;
                }
            }

            &__title{
               font-size: 30px;
               line-height: normal;
               color: #504B46;
               font-family: 'TsukuAVintageMinLPro-R';
               margin-bottom: 0;
                font-weight: 400;
            }
            &__sub{
                font-size: 30px;
                line-height: normal;
                color: #504B46;
                font-family: 'KaufmannStd-Bold';
            }
        }
        &__box{
           padding: 0px 40px 40px;
           overflow: auto;
           height: 215px;

           /* width */
            &::-webkit-scrollbar {
                width: 8px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #C3C3C3;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #B7E8E5;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #B7E8E5;
            }


       }
       &__item{
           border-bottom: 1px solid #504B46;
           padding: 15px 0;
           font-size: 16px;
           line-height: 1.8;
           color: #504B46;
           &__title{
            @include flex-col(170px);
            padding-right: 10px;
            color: #DEB69B;
           }
       }

    }
    @media (max-width: 1199px){
        &__wrap {
            &:before{
                right: 0;
            }
            &:after{
                left: 0;
            }
        }
    }
    @media (max-width: 991px){
        &__wrap {
            padding: 80px 60px 80px;
        }
        .el__box {
            padding: 0px 15px 40px 0;
        }
    }
    @media (max-width: 767px){
        &__wrap{
            &:before {
                width: 95px;
                height: 90px;
                top: -20px;
                right: 0;
            }
            &:after{
                width: 58px;
                height: 67px;
                left: 0;
            }
        }
    }
    @media (max-width: 575px){
        padding: 15px 0 35px;
        &__wrap {
            background-image: url(../images/home-info-mb.png);
            padding: 50px 30px 50px;
        }
        .el__item {
            font-size: 15px;
        }
        .el__header{
            &__wrap{

                padding-left: 45px;
                margin-left: -45px;
                &:before {
                    width: 43px;
                    height: 25px;
                }
            }
            &__title {
                font-size: 24px;
            }
            &__sub {
                font-size: 25px;
            }
        }
    }
}

.home-contact{
    padding: 50px 0;

    &__line{
        position: relative;
        width: 100%;
        height: 13px;
        margin: 15px 0;
        background-image: url(../images/home-contact-line1.png);
        background-repeat:no-repeat;
        background-position: center;
        background-size: contain;
        &::before,&::after{
            content: "";
            position: absolute;
            top: 2px;
            left: 0px;
            width: 13px;
            height: 13px;
            background-image: url(../images/dot.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &::after{
            top: -2px;
            left: auto;
            right: -1px;
        }

        &.-s2{
            background-image: url(../images/home-contact-line2.png);
            &::before{
                left: 0;
                top: 0;
            }
            &::after{
                left: auto;
                top: 2px;
                right: -1px;
            }
        }

    }
    .el__box{
        padding: 20px 0;
    }
    .col--left{
        position: relative;
        padding: 15px 50px;
        &:after{
            content: "";
            background-image: url(../images/home-contact-line-right.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
        }
    }
    .col--right{
        padding: 15px 50px;
    }
    .el{
        &__header{
            &__title{
                font-size: 25px;
                line-height: normal;
                color: #504B46;
                font-family: 'TsukuAVintageMinLPro-R';
                margin-bottom: 0;
                font-weight: 400;
            }
        }
        &__title{
          font-size: 25px;
          line-height: normal;
          color: #504B46;
        font-weight: 400;
          margin-bottom: 10px;
          font-family: 'TsukuAVintageMinLPro-R';
        }
        &__tel{
            font-size: 33px;
            line-height: normal;
            color: #7bded8;
            margin-bottom: 30px;
            padding-top: 10px;
						a{color: #7bded8;}
        }
    }
    &__qr{
        @include flex-col(133px);
        margin-left: 15px;
    }
    @media (max-width: 991px){
        .col--left {
            padding: 15px 30px;
        }
        .col--right {
            padding: 15px 30px;
        }
        .el__title {
            font-size: 21px;
        }
    }
    @media (max-width: 767px){
        &__line{
            background-size: auto;
            &::before {
                top: 0px;
                left: -2px;
            }
            &::after {
                top: 2px;
            }
            &.-s2:before{
                left: -2px;
            }
            &.-s2:after{
                top: 0px;
            }
        }
        .col--left{
            &:after{
                background-image: url(../images/line-bottom.png);
                background-repeat:repeat-x;
                background-position: center;
                background-size: contain;
                width: 80%;
                height: 3px;
                top: 100%;
                left: 0;
                max-width: 400px;
                margin: auto;
            }
        }
    }
    @media (max-width: 767px){
        .col--left{
            padding: 15px 20px;
        }
    }
    @media (max-width: 575px){
        padding: 30px 0 50px;

        .el{
            &__box{
                padding: 0;
            }
            &__header__title {
                font-size: 22px;
            }
            &__line{
                margin: 15px 0;
                background-image: url(../images/home-contact-line1-mb.png);
                &.-s2{
                    background-image: url(../images/home-contact-line2-mb.png);
                }
            }
        }
    }
}

.home-calendar{
    margin-bottom: 60px;

    .el{
        &__header{
            margin-bottom: 20px;
            &__wrap{
                display: inline-block;
                margin: auto;
                position: relative;
                padding-left: 55px;
                margin-left: -55px;
                &:before{
                    content: "";
                    background-image: url(../images/home-calendar-icon.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 42px;
                    height: 34px;
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0;;
                }
            }
            &__title{
               font-size: 24px;
               line-height: normal;
               color: #504B46;
               font-family: 'TsukuAVintageMinLPro-R';
            }
            &__sub{
                font-size: 25px;
                line-height: normal;
                color: #504B46;
                font-family: 'KaufmannStd-Bold';
            }
        }
    }
    @media (min-width: 768px){
        padding: 50px 0;
        .el__header {
            margin-bottom: 60px;
        }
    }

}

.home-medical{
    padding: 265px 0 220px;
    background-image: url(../images/home-medical-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    overflow: hidden;
    .col--left{
        align-items: center;
    }
    .el{
        &__header{
            margin-bottom: 15px;
            &__title{
                font-size: 50px;
                line-height: normal;
                letter-spacing: 20px;
                font-family: 'TsukuAVintageMinLPro-R';
                font-weight: 400;
                margin-bottom: 0;
                span{
                    position: relative;
                    color: #7bded8;
                    &::before{
                        content: "";
                        background-image: url(../images/crown.png);
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center;
                        width: 92px;
                        height: 70px;
                        position: absolute;
                        top: -75px;
                        left: -20px;
                        z-index: -1;
                    }
                }
            }
        }
        &__title{
            font-size: 33px;
            line-height: normal;
            font-weight: 400;
            margin-bottom: 30px;
            font-family: 'TsukuAVintageMinLPro-R';
        }
        &__excerpt{
            line-height: 1.8;
        }

        &__thumb{
            position: relative;
            max-width: 715px;
            margin-bottom: 30px;
            margin-left: auto;
            .dnfix__thumb{
                padding-top: 71%;
            }
            &:before{
                content: "";
                background-image: url(../images/home-medical-border.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: calc(100% + 15px);
                height: calc(100% + 17px);
                position: absolute;
                top: -20px;
                left: -28px;
                z-index: 1;
            }
            img{
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-image: url(../images/home-medical-mask.png);
                mask-image: url(../images/home-medical-mask.png);
            }
        }
    }
    @media (max-width: 1599px){
        padding: 240px 0 120px;
        .el__title {
            font-size: 28px;
        }
        .el__thumb {
            margin-left: 20px;
        }
    }
    @media (max-width: 991px){
        .el__thumb {
            margin: 0 auto 30px;
        }

        .el__title {
            text-align: center;
        }
    }
    @media (max-width: 767px){
        background-image: url(../images/home-medical-mb-bg.png);
        padding: 115px 0 120px;
        .el__thumb{
            margin:0 15px 30px;
            &:before {
                width: calc(100% + 10px);
                left: -10px;
            }

        }
    }
    @media (max-width: 575px){
        padding: 115px 0 80px;
        .el{
            &__header{
                &__title{
                    font-size: 28px;
                }
                span::before {
                    width: 60px;
                    height: 50px;
                    top: -50px;
                    left: -17px;
                }
            }
        }
        .el__box {
            padding: 0px 0;
        }
        .el__title {
            font-size: 23px;
        }
        &__line{
            height: 17px;
            margin: 15px 0;
            background-image: url(../images/home-contact-line1-mb.png);
            &.-s2{
                background-image: url(../images/home-contact-line2-mb.png);
            }
        }
    }

    @media (min-width: 1600px){
        .col--left{
            flex: 0 0 auto;
            width: 41.6666666667%;
        }
    }

}

.home-greeting{
    padding: 100px 0;
    overflow: hidden;
    .el{
        &__user{
            &__position{
                font-size: 20px;
                font-weight: 400;
                line-height: 1.8;
                margin-bottom: 15px;
            }
            ruby{
                font-size: 26px;
                line-height: normal;
                margin-bottom: 30px;
            }
            rt{
                font-size: 14px;
                line-height: normal;
            }
        }
        &__header{
            position: relative;
            max-width: 1020px;
            margin: 0 auto 30px;
            &__wrap{
                position: relative;
                display: block;
                &:before{
                    content: "";
                    background-image: url(../images/home-greeting-header.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1020px;
                    max-width: 100%;
                    height: 350px;
                    z-index: -1;
                }
            }
            &__title{
                position: relative;
                font-size: 44px;
                line-height: 77px;
                padding: 125px 0 60px 205px;
                font-family: 'TsukuAVintageMinLPro-R';
                font-weight: 400;
                margin-bottom: 0;
            }
        }
        &__title{
            font-size: 33px;
            line-height: 54px;
            font-weight: 400;
            margin-bottom: 30px;
            font-family: 'TsukuAVintageMinLPro-R';
        }
        &__excerpt{
            margin-bottom: 50px;
            line-height: 1.8;
        }
        &__text{
            font-size: 20px;
            font-weight: 400;
            line-height: 1.8;
            margin-bottom: 0;
        }
        &__line{
            width: 100%;
            height: 17px;
            margin: 0 0 15px;
            background-image: url(../images/home-greeting-line.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        &__clgt{
            margin-bottom: 30px;
        }
        &__thumb{
            position: relative;
            max-width: 520px;
            margin: 0 auto 40px;
            .dnfix__thumb{

                padding-top: 100%;
            }

            &:before{
                content: "";
                background-image: url(../images/greeting-img-border.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: calc(100% + 45px);
                height: calc(100% + 40px);
                position: absolute;
                top: -23px;
                left: -40px;
                z-index: 1;
            }
            img{
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-image: url(../images/greeting-img-mask.png);
                mask-image: url(../images/greeting-img-mask.png);
            }

        }
    }
    @media (max-width: 1299px){
        .el__thumb {
            margin: 0 50px 40px;
        }
    }
    @media (max-width: 991px){
        padding: 0 0 50px;
        .el__thumb {
            margin: 0 auto 40px;
        }
        .el__user{
            margin-bottom: 20px;
        }
        .el__title {
            text-align: center;
        }
    }
    @media (max-width: 767px){
        padding: 80px 0 50px;
        .el{
            &__header{
                &__title {
                    padding: 70px 0 20px 155px;
                }
                &__wrap{
                    &:before{
                        background-image: url(../images/home-greeting-mb-header.png);
                        height: 155px;
                    }
                }
            }
            &__excerpt {
                font-size: 15px;
            }
        }

    }
    @media (max-width: 620px){
        .el__thumb {
            margin: 0 50px 40px;
        }
    }
    @media (max-width: 575px){
        .el{
            &__header {
                margin: 0 auto 0;
                &__title {
                    padding: 0 10px 40px 40px;
                    font-size: 28px;
                }
                &__wrap{
                    &:before{
                        top:-45px;
                    }
                }
            }
            &__position {
                font-size: 16px;
            }
            &__name {
                font-size: 11px;
            }
            &__address {
                font-size: 21px;
            }
            &__title {
                font-size: 24px;
                line-height: normal;
            }
            &__line{
                background-image: url(../images/home-greeting-line-mb.png);
            }
        }
    }
    @media (min-width: 1600px){
        .container{
            max-width: 1330px;
        }
    }
}

.home-featured{
    background-image: url(../images/home-featured-bg.png);
    padding: 220px 0 130px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    overflow: hidden;
    .el{
        &__header{
            margin-bottom: 120px;
            &__wrap{
                position: relative;
                display: inline-block;
                &:before{
                    content: "";
                    background-image: url(../images/home/home-featured-title.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: -180px;
                    left: 25%;
                    width: 490px;
                    height: 420px;
                    z-index: -1;
                }
            }
            &__title{
                font-size: 44px;
                font-weight: 400;
                margin-bottom: 0;
                line-height: normal;
                letter-spacing: 9px;
                font-family: 'TsukuAVintageMinLPro-R';
            }
            &__sub{
                font-size: 35px;
                line-height: normal;
                color: #7bded8;
                font-family: 'KaufmannStd-Bold';
            }
        }
        &__row{
            padding: 45px 0;
        }
        &__top{
            margin-bottom: 30px;
        }
        &__title{
            font-size: 30px;
            font-weight: 400;
            line-height: 52px;
            margin-bottom: 0;
        }
        &__number{
            position: relative;
            top: 6px;
            @include flex-col(100px);
            text-align: center;
            img{
                max-height: 83px;
            }
        }
        &__thumb {
            position : relative;
            max-width: 730px;
            margin-left: auto;
            margin-bottom: 15px;

            .dnfix__thumb{
                padding-top: 66%;
            }

            &:before{
                content: "";
                background-image: url(../images/featured-border-1.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: calc(100% + 5px);
                height: calc(100% + 10px);
                position: absolute;
                top: 0;
                left: -15px;
                z-index: 1;
            }
            img{
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-image: url(../images/featured-mask.png);
                mask-image: url(../images/featured-mask.png);
            }
            &.-st2{
                &:before{
                    background-image: url(../images/featured-border-2.png);
                }
            }
            &.-st3{
                &:before{
                    background-image: url(../images/featured-border-3.png);
                }
            }
            &.-st4{
                &:before{
                    background-image: url(../images/featured-border-4.png);
                }
            }
        }
    }
    @media (max-width: 1599px){
        padding: 100px 0 150px;
        .el__header {
            margin-bottom: 50px;
        }
        .row.-row80{--bs-gutter-x: 40px;}
    }

    @media (max-width: 1200px){
        padding: 80px 0 80px;
        .el__header__wrap:before {
            width: 330px;
            height: 280px;
            top: -75%;
        }
        .el__number{
            @include flex-col(75px);
            img{
                max-height: 83px;
            }
        }
        .el__row {
            padding: 30px 0;
        }
        .el__top {
            margin-bottom: 15px;
        }
        .el__title {
            font-size: 25px;
        }
    }
    @media (max-width: 991px){
        padding: 50px 0 40px;
        .el__thumb {
            max-width: 550px;
            margin-right: auto;
        }
    }
    @media (max-width: 767px){
        background-image: url(../images/home-featured-mb-bg.png);
        .el__title {
            line-height: normal;
        }
        .el__number{
            top: 12px;
            img {
                max-height: 60px;
            }
        }
        .el__top{
            margin-bottom: 20px;
        }
        .el__thumb {
            max-width: 100%;
            margin-right: auto;
            margin-bottom: 20px;
            &:before{
                left: -5px;
            }
        }
        .el__meta {
            font-size: 15px;
            line-height: 26px;
        }
    }
    @media (max-width: 575px){


        .el__header__wrap:before {
            width: 220px;
            height: 190px;
        }
        .el__header__title {
            font-size: 28px;
        }
        .el__header__sub {
            font-size: 25px;
        }
        .el__title {
            font-size: 18px;
            line-height: 28px;
        }
        .el__number {
            @include flex-col(45px);
            top: 4px;
            img {
                max-height: 50px;
            }
        }
    }
    @media (min-width: 1600px){
        .col--left{
            flex: 0 0 auto;
            width: 41.6666666667%;
        }
    }


}

.home-service{
    position: relative;
    // background-image: url(../images/home-service-bg.png);
    padding: 250px 0 160px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    .container{
        position: relative;
        z-index:1;
    }
    &:before{
        content:"";
        background-image: url(../images/home-service-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    ul{
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li{
           &:nth-child(2n){
            .el__title{
                background-image: url(../images/box-2.png);
            }
           }
        }
    }
    .el{
        &__box{
            max-width: 960px;
            margin: auto;
            min-height: 530px;
        }
        &__header{

            margin-bottom: 100px;
            &__wrap{
                position: relative;
                display: inline-block;
                &:before{
                    content: "";
                    background-image: url(../images/home-service-title.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: -90px;
                    left: -10px;
                    width: 365px;
                    height: 235px;
                    z-index: -1;
                }
            }
            &__title{
                font-size: 44px;
                font-weight: 400;
                margin-bottom: 0;
                letter-spacing: 6px;
                line-height: normal;
                font-family: 'TsukuAVintageMinLPro-R';
            }
            &__sub{
                font-size: 35px;
                line-height: normal;
                color: #7bded8;
                font-family: 'KaufmannStd-Bold';
            }
        }
        &__item{
            display: block;
            font-size: 18px;
            color: #504B46;
            text-align: center;
            text-decoration: none;
            margin-bottom: 50px;
        }
        &__thumb{
            height: 80px;
            margin-bottom: 15px;
        }
        &__title{
            position: relative;
            line-height: 22px;
            background-image: url(../images/box-1.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100% 100%;
            padding: 12px 0px 26px;
        }
    }
    @media (max-width: 1599px){
        padding: 100px 0 150px;
        .el__header {
            margin-bottom: 50px;
            &__wrap{
                &:before{
                    top: -75px;
                    left: -10px;
                    width: 280px;
                    height: 235px;
                }
            }
        }

    }
    @media (max-width: 1499px){
        background-size: cover;
        &:before{
            background-size: cover;
        }

    }

    @media (max-width: 991px){
        padding: 120px 0 80px;
    }
    @media (max-width: 767px){
        .el__box {
          min-height: auto;
        }
        &:before{
            background-image: url(../images/home-service-bg2.png);
        }
        &:after{
            content: "";
            background: url(../images/home-service-bg-mb.png);
            position: absolute;
            top: -100px;
            left: 0;
            width: 100%;
            height: calc(100% + 200px);
            background-size: cover;
        }

        ul {
            --bs-gutter-x: 1rem;
        }
        .el__title{
            font-size:13px;
        }
    }
    @media (max-width: 575px){
        .el__item {
            font-size: 14px;
            margin-bottom: 10px;
        }
        .el__header__wrap:before {
            top: -40px;
            left: -10px;
            width: 215px;
            height: 140px;
        }
        .el__header__title {
            font-size: 28px;
        }
        .el__header__sub {
            font-size: 25px;
        }
        .el__thumb {
            height: 60px;
            margin-bottom: 15px;
            img{
                max-height:50px;
                max-width: 60px;
            }
        }
    }

}

.home-clinic{
    padding: 120px 0 65px;
    .el{
        &__header{
            margin-bottom: 100px;
            &__wrap{
                position: relative;
                display: inline-block;
                &:before{
                    content: "";
                    background-image: url(../images/home/home-clinic-title.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: -90px;
                    left: -85px;
                    width: 365px;
                    height: 235px;
                    z-index: -1;
                }
            }
            &__title{
                font-size: 44px;
                letter-spacing: 9px;
                line-height: normal;
                font-family: 'TsukuAVintageMinLPro-R';
                font-weight: 400;
                margin-bottom: 0;
            }
            &__sub{
                font-size: 35px;
                line-height: normal;
                color: #7bded8;
                font-family: 'KaufmannStd-Bold';
            }
            &__excerpt{
                margin-bottom: 50px;
            }
        }
        &__box{
            margin-bottom: 30px;
        }
        &__item{
            display: block;
            text-align: center;
            margin-bottom: 30px;
            font-size: 23px;
            line-height: 1.8;
            color: #504B46;
        }
        &__thumb{
            position: relative;
            img{
                border-radius: 15px;
            }

            &:after{
                content: "";
                background-image: url(../images/home-clinic-border.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100% 100%;
                position: absolute;
                top: -15px;
                left: -12px;
                width: calc(100% + 6px);
                height: calc(100% + 10px);
            }
            &.-border-2{
                &:after{
                    background-image: url(../images/home-clinic-border-2.png);
                }
            }
            &.-border-3{
                &:after{
                    background-image: url(../images/home-clinic-border-3.png);
                }
            }
        }
    }
    @media (max-width: 767px){
        .el__item {
            // margin-bottom: 30px;
        }
        .el__thumb{
            margin-bottom: 10px;
            &:after {
                top: -8px;
                left: -8px;
            }
        }

    }
    @media (max-width: 575px){
        .el__header {
            margin-bottom: 50px;
        }
        .el__header__title {
            font-size: 28px;
        }
        .el__header__sub {
            font-size: 25px;
        }
        .el__header__wrap:before {
            top: -60px;
            left: -60px;
            width: 225px;
            height: 160px;
        }
        .el__item {
            font-size: 14px;
            line-height: normal;
        }
    }
}

.home-visitus{
    padding: 140px 0 60px;
    overflow: hidden;
    .el{
        &__header{
            margin-bottom: 100px;
            &__wrap{
                position: relative;
                display: inline-block;
                padding-left: 44px;
                margin-left: -44px;
                &:before{
                    content: "";
                    background-image: url(../images/visitus-flag.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 43px;
                    height: 49px;
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    z-index: -1;
                }

                &:after{
                    content: "";
                    background-image: url(../images/visitus-title.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    top: -90px;
                    left: 10px;
                    width: 365px;
                    height: 235px;
                    z-index: -1;
                }
            }
            &__title{
                font-size: 35px;
                letter-spacing: 5px;
                line-height: normal;
                font-weight: 400;
                margin-bottom: 0;
                font-family: 'TsukuAVintageMinLPro-R';
            }
            &__sub{
                font-size: 35px;
                line-height: normal;
                font-weight: 400;
                margin-bottom: 0;
                font-family: 'KaufmannStd-Bold';
            }
        }
        &__title{
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 0;
            line-height: 43px;
            color: #504B46;
            font-family: 'TsukuAVintageMinLPro-R';
        }
        &__item{
            margin-bottom: 30px;
            img{
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    .instagram__box{
        margin-top: 90px;
        .el__line{
            margin-bottom: 35px;
        }
        .instagram__item{
            margin-bottom: 30px;
        }
    }
    @media (max-width: 991px){
        padding: 60px 0 30px;
    }
    @media (max-width: 575px){
        .el__title {
            font-size: 20px;
        }
        .el__header {
            margin-bottom: 40px;
        }
        .el__header__title {
            font-size: 28px;
        }
        .el__header__sub {
            font-size: 25px;
        }
        .el__header__wrap:after {
            top: -45px;
            left: 65px;
            width: 175px;
            height: 155px;
        }
    }
}

.home-clgt{
    font-size: 16px;
    line-height: 32px;
    color: #504B46;
    padding: 0px 0 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url(../images/home-clgt-bg.png);
    .el{
        &__box{
            position: relative;
            background-image: url(../images/home-clgt-box.png);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            padding: 100px 50px 50px;
            &:before{
                content: "";
                background-image: url(../images/home-clgt-bg-top.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width:  267px;
                height: 87px;
                display: block;
                position: absolute;
                top: -20px;
                left: -40px;
            }

            &:after{
                content: "";
                background-image: url(../images/home-clgt-pc-bg-bottom.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -25px;
                right: -105px;
                width: 165px;
                height: 172px;
            }

        }
        &__item{
            padding:20px 20px;
            &--left{
                @include flex-col(160px);
                margin-right: 20px;
                text-align: center;
            }
            &--right{
                flex-grow: 1;
            }
            &.-st1{
                background-image: url(../images/home-clgt-item-1.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &.-st2{
                background-image: url(../images/home-clgt-item-2.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            &.-st3{
                background-image: url(../images/home-clgt-item-3.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

    }
    @media (max-width: 1240px){
        .el__box:after {
            right: -40px;
            width: 135px;
            height: 135px;
        }
    }
    @media (max-width: 1120px){
        .el__box{
            &:before{
                width: 230px;
                height: 75px;
                top: -30px;
                left: -10px;
            }
            &:after{
                bottom: -25px;
                right: -10px;
                width: 120px;
                height: 120px;
            }
        }
    }
    @media (max-width: 991px){
        padding: 0px 0 50px;
    }
    @media (max-width: 767px){
        background: transparent;
        .el__box{
            background: transparent;
            padding: 50px 30px 70px;
            &:before{
                content: "";
                background-image: url(../images/home-clgt-bg-top.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                width: 230px;
                height: 75px;
                display: block;
                position: absolute;
                top: 10px;
                left: 0;
                z-index: 1;
            }

            &:after{
                content: "";
                background-image: url(../images/home-clgt-bg-bottom.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                bottom: 0px;
                right: 30px;
                width: 70px;
                height: 74px;
                z-index: 1;
            }
        }
    }
    @media  (max-width: 575px ){

        font-size:14px;

        .el__box {
            padding: 65px 0px 70px;
        }
        .el__item--left {
            @include flex-col(60px)
        }
    }
    @media  (min-width: 1400px ){
        .container-sm{
            max-width: 1180px;
        }
    }
}

.home-3box{
    padding-bottom: 100px;
    .el{
        &__line{
            position: relative;
            width: 100%;
            height: 13px;
            margin: 15px 0;
            background-image: url(../images/home-3box-line-1.png);
            background-repeat:no-repeat;
            background-position: center;
            background-size: contain;
            &::before,&::after{
                content: "";
                position: absolute;
                top: 2px;
                left: 0px;
                width: 13px;
                height: 13px;
                background-image: url(../images/dot.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
            &::before{
                left: -2px;
            }
            &::after{
                top: -2px;
                left: auto;
                right: -1px;
            }

            &.-s2{
                background-image: url(../images/home-3box-line-2.png);
                &::before{
                    top: 0;
                }
                &::after{
                    left: auto;
                    top: 2px;
                    right: -1px;
                }
            }

        }

        &__col{
            position: relative;
            &:not(:last-child)::after{
                content: "";
                background-image: url(../images/line-right.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                position: absolute;
                top: 0;
                right: 0;
                width: 4px;
                height: 100%;

            }
            &:first-child{
                .el__item{
                    padding-left: 0;
                }
            }
            &:nth-child(3n){
                .el__item{
                    padding-right: 0;
                }
            }
        }
        &__item{
            padding: 15px 40px;
        }
        &__title{
            font-size: 20px;
            font-weight: 400;
            line-height: 1.8;
            color: #504B46;
            margin-bottom: 15px;
            font-family: 'TsukuAVintageMinLPro-R';
            text-align: center;
        }
        &__excerpt{
            min-height: 150px;
        }
    }
    img{
        margin: 25px 0;
    }
    @media  (max-width: 991px ){
        padding-bottom: 50px;
        .el__item {
            padding: 15px 15px;
        }
    }
    @media (max-width: 767px){
        padding-bottom: 30px;
        .el{
            &__title{
                text-align: center;
            }
            &__excerpt {
                min-height: auto;
                margin-bottom: 15px;
            }
            &__col{
                position: relative;
                &:not(:last-child){
                    margin-bottom: 30px;
                    &::after{
                        content: "";
                        background-image: url(../images/line-bottom.png);
                        background-position: center;
                        background-repeat: repeat-x;
                        background-size: contain;
                        position: absolute;
                        top: 100%;
                        right: 15px;
                        width: calc(100% - 30px);
                        height: 4px;

                    }
                }
                &:first-child{
                    .el__item{
                        padding-top: 0;
                        padding-left: 15px;
                    }
                }
                &:nth-child(3n){
                    .el__item{
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    @media (max-width: 575px){
        .el{
            // &__line{
            //     background-image: url(../images/home-3box-line-1-mb.png);
            //     &.-s2{
            //         background-image: url(../images/home-3box-line-2-mb.png);
            //     }
            // }
        }
    }
}
