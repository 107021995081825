.footer {
	background: #F2E2D7;
	position: relative;
	padding: 90px 0 40px;
	font-size: 18px;
	line-height: 35px;

	.container {
		max-width: 1400px; }

	.el {
		&__title {
			margin-bottom: 15px; }

		&__sub {
			font-size: 18px;
			line-height: 35px;
			margin-bottom: 10px; }

		&__tel {
			font-size: 30px;
			line-height: 52px;
			margin-bottom: 15px; } }

	.schedule {
		margin-top: 15px; }

	&__socical {
		margin-bottom: 30px;
		text-align: center;

		a {
			&:not(:last-child) {
				margin-right: 40px; }

			img {
				border-radius: 50%;
				transition: all .5s; }

			&:hover {
				img {
					box-shadow: 0px 2px 5px #222;
					transform: translateY(-5px); } } } }

	&__nav {
		font-size: 18px;
		background-color: #fff;
		padding: 20px 0;

		ul {
			list-style: none;
			display: flex;
			flex-direction: row;
			padding-left: 0;
			margin: 0;
			list-style: none;
			justify-content: center;

			li {
				position: relative;
				text-align: center; }

			a {
				position: relative;
				display: block;
				margin: 5px 20px;
				color: #504B46;
				text-decoration: none;
				transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;

				&::after {
					content: "";
					width: 100%;
					height: 1px;
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					background-color: #504B46; } } }

		@media (max-width: 1024px) {
			ul a {
				margin: 5px 15px; } }

		@media (max-width: 991px) {
			ul {
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: center;

				li {
					flex-grow: inherit; } } } }

	&__map {
		height: 0;
		overflow: hidden;
		padding-bottom: 80%;
		position: relative;
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%; } }

	&__copyright {
		padding: 10px 0;
		text-align: center;
		font-size: 16px;
		line-height: 35px;
		color: #504B46; }

	@media (max-width: 1599px) {
		.el__title {
			font-size: 32px; } }

	@media (max-width: 767px) {
		padding: 40px 0 40px;
		font-size: 14px;
		line-height: normal;

		.el {
			&__box {
				text-align: center; }

			&__title {
				margin-bottom: 10px; }

			&__tel {
				font-size: 28px;
				line-height: 49px;
				margin-bottom: 5px;
				color: #7bded8; }

			&__sub {
				font-size: 14px;
				line-height: 25px;
				margin-bottom: 0; } }

		&__map {
			margin: 0 -15px; }

		&__socical {
			margin-top: 30px;
			margin-bottom: 0; }

		.container-sm {
			padding-right: 15px;
			padding-left: 15px; }

		.row.no-gutters50 {
			margin-right: -15px;
			margin-left: -15px; }

		.row.no-gutters50 > [class^="col"], .row.no-gutters50 > [class*=" col"] {
			padding-right: 15px;
			padding-left: 15px; }

		&__copyright {
			font-size: 14px;
			padding-bottom: 70px; } } }

/*back to top */
.back__to--top {
	position: fixed;
	bottom: -100px;
	right: 20px;
	cursor: pointer;
	z-index: 999;
	opacity: 0;
	transition: all .5s; }

.back__to--top.active {
	bottom: 100px;
	opacity: 1; }

.back__to--top img {
	width: 80px;
	height: 80px; }

@media (max-width: 767px) {
	.back__to--top {
		bottom: -45px; }

	.back__to--top img {
		width: 50px;
		height: 50px; } }

.button__fix {
	&__pc {
		position: fixed;
		top: 110px;
		z-index: 99;
		right: 0;

		a {
			display: block;
			background: #B7E8E5;
			color: #504B46;
			font-size: 18px;
			line-height: 35px;
			padding: 30px 7.5px;
			writing-mode: vertical-rl;
			-webkit-writing-mode: vertical-rl;
			-ms-writing-mode: vertical-rl;
			margin-bottom: 15px;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;

			&:hover {
				background-color: #7bded8; } } }

	&__mb {
		display: none;
		position: fixed;
		bottom: 0;
		z-index: 99;
		left: 0;
		width: 100%;
		justify-content: center;

		a {
			display: block;
			background: #B7E8E5;
			color: #fff;
			font-size: 18px;
			line-height: 33px;
			text-align: center;
			padding: 5px 7.5px;
			margin-bottom: 0;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			min-width: 162px;

			&:first-child {
				background-color: #504B46; }

			+ a {
				margin-left: 10px; }

			img {
				position: relative;
				top: 6px;
				margin-right: 10px; }

			&:hover {
				background-color: #d8817e; } } }

	@media (max-width: 767px) {
		&__pc {
			display: none; }

		&__mb {
			display: flex; } }

	@media (min-width: 768px) {
		&__pc {
			display: block; } } }
